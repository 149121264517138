import React, { FC } from 'react';
import { List } from 'antd';
import { UserItemData } from '~/Services/UserService.d';
import { UserAvatar } from '../UserAvatar';
import { Utils } from '~/Utils';

interface UserItemMetaProps {
  user: UserItemData;
}

export const UserItemMeta: FC<UserItemMetaProps> = ({ user }) => {
  return (
    <List.Item.Meta
      key={user.id}
      avatar={<UserAvatar user={user} />}
      title={Utils.combineStrings([user.firstname, user.lastname]) || user.email}
      description={user.email}
    />
  );
};
