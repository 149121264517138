import React, { useState, useEffect } from 'react'
import { Form, Input, Modal, InputNumber, Select, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '~/Store'
import {
  getIsConfirmLoading,
  getLicenseTypeSelected,
  getModalVisibility,
  getProductList,
  LicenseMgmtActions,
  ModalName,
} from '~/Store/License'
import { LicenseTypeEnum } from '~/Types/Enums'
import { errorNotification, ProductService } from '~/Services'
import { IProductClaim } from '~/Services/Product.interface'

const { Option } = Select

export const EditLicenseType = () => {
  const dispatch = useAppDispatch()
  const visible = useAppSelector(getModalVisibility(ModalName.LicenseType))
  const confirmLoading = useAppSelector(getIsConfirmLoading)
  const licenseType = useAppSelector(getLicenseTypeSelected)
  const productList = useAppSelector(getProductList)
  const [productClaims, setProductClaims] = useState<IProductClaim[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    if (productList.length > 0) {
      const productId = productList[0].guid
      form.setFieldsValue({ productId })
      getProductClaimsByGuid(productId)
    }
  }, [productList])

  const getProductClaimsByGuid = async (guid: string) => {
    try {
      form.setFieldsValue({ productClaims: [] })
      const claimsRes = await ProductService.Get.getProductClaimsById(guid)
      const { data } = claimsRes
      setProductClaims(data)
      if ((data as IProductClaim[]).length > 0) {
        form.setFieldsValue({ productClaims: [data[0]['guid']] })
      }
    } catch (error) {
      errorNotification(error)
    }
  }

  // todo: allow edit claims
  // const [licenseTypeDetail, setLicenseTypeDetail] =
  //   useState<ILicenseTypeDetail>()
  // useEffect(() => {
  //   if (licenseType) {
  //     getLicenseTypeById(licenseType.guid)
  //   }
  // }, [licenseType])
  // const getLicenseTypeById = async (guid: string) => {
  //   try {
  //     setIsLoading(true)
  //     const typeRes = await LicenseService.Get.getLicenseInfoById(guid)
  //     const typeData: ILicenseTypeDetail = typeRes.data
  //     setLicenseTypeDetail(typeData)
  //     if (typeData.products.length > 0) {
  //       const product = typeData.products[0]
  //       await getProductClaimsByGuid(product.guid)
  //       const claims: string[] = []
  //       product.claims.forEach(claim => claims.push(claim.guid))
  //       form.setFieldsValue({ productId: product.guid, productClaims: claims })
  //     }
  //   } catch (error) {
  //     errorNotification(error)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  const seftClose = () => {
    dispatch(LicenseMgmtActions.setLicenseTypeSelected(undefined))
    dispatch(
      LicenseMgmtActions.setModalVisibility({
        name: ModalName.LicenseType,
        visible: false,
      }),
    )
  }

  const onTypeSubmit = () => {
    form.validateFields().then(values => {
      const licenseTypeData = {
        offlineRange: values['offlineRange'],
        type: values['type'],
        name: values['name'],
      }
      if (licenseType) {
        dispatch(
          LicenseMgmtActions.updateLicenseType({
            ...licenseTypeData,
            guid: licenseType.guid,
          }),
        )
      } else {
        dispatch(
          LicenseMgmtActions.createLicenseType({
            ...licenseTypeData,
            licenseTypeProductClaims: values['productClaims'].map(
              (claimId: string) => ({
                productClaimId: claimId,
              }),
            ),
          }),
        )
      }
    })
  }

  const handleProductChange = (guid: string) => {
    getProductClaimsByGuid(guid)
  }

  return (
    <Modal
      visible={visible}
      title={licenseType ? 'Edit Type' : 'New Type'}
      confirmLoading={confirmLoading}
      onCancel={seftClose}
      onOk={onTypeSubmit}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          initialValues={{
            ...licenseType,
            type: licenseType?.type || LicenseTypeEnum.free,
            offlineRange: licenseType?.offlineRange || 0,
          }}
          layout="vertical"
          onFinish={onTypeSubmit}
          autoComplete="off"
        >
          {!licenseType && (
            <Form.Item noStyle>
              <Form.Item
                label="Product"
                name="productId"
                style={{
                  display: 'inline-block',
                  width: '50%',
                  paddingRight: '8px',
                }}
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Select onChange={handleProductChange}>
                  {productList.map(p => (
                    <Option key={p.guid} value={p.guid}>
                      {p.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Claims"
                name="productClaims"
                style={{
                  display: 'inline-block',
                  width: '50%',
                }}
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Select mode="multiple">
                  {productClaims.map(c => (
                    <Option key={c.guid} value={c.guid}>
                      {c.claimValue}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          )}
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: 'Required!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Required!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Offline range"
            name="offlineRange"
            rules={[{ required: true, message: 'Required!' }]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}
