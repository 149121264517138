import { Device } from '~/Store/Devices'
import { Delete } from './Delete'
import { Get } from './Get'
export interface Filter {
  filter?: string
  expand?: string
  entitySetName?: string
  paging?: boolean
  orderBy?: string
  page?: number
  pageSize?: number
  offset?: number
  take?: number
  total?: number
  userName?: string
  deviceName?: string
  devicePlatform?: string
}

export interface DataResponse {
  results: Device[]
  page: number
  pageCount: number
  pageSize: number
  queryCount: number
  firstRowIndex: number
  lastRowIndex: number
}

export interface DeactivateDeviceData {
  userId: string
  mac: string
  clientID: string
}
class DevicesServiceController {
  // prettier-ignore
  private licenseManagerUrl = process.env.LICENSE_MANAGER_URL ?? `${window.location.protocol}//${window.location.hostname}/license_manager`

  private url = `${this.licenseManagerUrl}/Devices`
  public getDevices(data?: Filter) {
    let url = `${this.url}`
    const filterData = { params: { ...data } }
    return Get(url, filterData)
  }

  public deactivateDevices = (data: DeactivateDeviceData[]) =>
    Delete(`${this.url}/deactive/user`, data)
}

export const DevicesService = new DevicesServiceController()
