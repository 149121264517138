import React, { useEffect } from 'react'
import { Col, Row, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

const { Title, Paragraph } = Typography

export const RegisterConfirmation = () => {
  const history = useHistory()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navToLogin()
    }, 10000)
    return () => clearTimeout(timeoutId)
  }, [])

  const navToLogin = () => {
    history.replace('/')
  }
  const handleKeyDown = (ev: any) => {
    // check keys if you want
    if (ev.keyCode === 13) {
      navToLogin()
    }
  }
  return (
    <Row>
      <Col offset={6} span={12} style={{ marginTop: '25px' }}>
        <Title level={2}>
          Cám ơn bạn đã đăng ký tài khoản tại hệ thống của công ty công nghệ
          Hicas
        </Title>
        <Paragraph>
          Một thư mới đã được gửi đến email của bạn. Hãy kiểm tra email của bạn
          và truy cập vào{' '}
          <a
            onClick={navToLogin}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
          >
            địa chỉ này
          </a>{' '}
          để đăng nhập và sử dụng dịch vụ.
        </Paragraph>
      </Col>
    </Row>
  )
}
