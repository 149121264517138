import { Button, Pagination, Row, Spin, Typography, Modal } from 'antd'
import React, { useEffect } from 'react'
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '~/Store'
import {
  getIsLoading,
  getLicenseTypes,
  getModalVisibility,
  getPageMeta,
  LicenseMgmtActions,
  ModalName,
} from '~/Store/License'
import { getLeftSiderCollapsed } from '~/Store/Layout/selectors'
import { ScrollBar } from '~/Components/ScrollBar'
import { EditLicenseType } from './EditLicenseType'
import { ILicenseType } from '~/Services/License.interface'
import { LicenseTypeDetail } from './LicenseTypeDetails'

const { confirm } = Modal

export const AdminLicenses = () => {
  const dispatch = useAppDispatch()
  const licenseTypes = useAppSelector(getLicenseTypes)
  const pageMeta = useAppSelector(getPageMeta)
  const isLoading = useAppSelector(getIsLoading)
  const leftMenuCollapsed = useAppSelector(getLeftSiderCollapsed)
  const editTypeModal = useAppSelector(
    getModalVisibility(ModalName.LicenseType),
  )
  const detailVisible = useAppSelector(
    getModalVisibility(ModalName.LicenseTypeDetail),
  )

  useEffect(() => {
    dispatch(LicenseMgmtActions.licenseTypeAdminLoadData())
  }, [])

  const changePage = async (_page: number, _pageSize?: number | undefined) => {
    dispatch(
      LicenseMgmtActions.getLicenseTypes({
        pageSize: _pageSize,
        page: _page,
      }),
    )
  }

  const newTypeClick = () => {
    dispatch(LicenseMgmtActions.setLicenseTypeSelected(undefined))
    dispatch(
      LicenseMgmtActions.setModalVisibility({
        name: ModalName.LicenseType,
        visible: true,
      }),
    )
  }

  const editTypeClick = (item: ILicenseType) => {
    dispatch(LicenseMgmtActions.setLicenseTypeSelected(item))
    dispatch(
      LicenseMgmtActions.setModalVisibility({
        name: ModalName.LicenseType,
        visible: true,
      }),
    )
  }

  const deleteLicenseTypeClick = (item: ILicenseType) => {
    confirm({
      title: 'Do you Want to delete this item?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <div>{`Type: ${item.type}`}</div>
          <div>{`Name: ${item.name}`}</div>
          <div>{`Offline range: ${item.offlineRange}`}</div>
        </div>
      ),
      onOk() {
        dispatch(LicenseMgmtActions.deleteLicenseType(item.guid))
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const licenseTypeDetailClick = (item: ILicenseType) => {
    dispatch(LicenseMgmtActions.setLicenseTypeSelected(item))
    dispatch(
      LicenseMgmtActions.setModalVisibility({
        name: ModalName.LicenseTypeDetail,
        visible: true,
      }),
    )
  }

  return (
    <div>
      {editTypeModal && <EditLicenseType />}
      {detailVisible && <LicenseTypeDetail />}
      <Row align="stretch">
        <Typography.Title style={{ flex: 1 }} level={4}>
          License Types
        </Typography.Title>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={newTypeClick}
        >
          New Type
        </Button>
      </Row>
      <Spin spinning={isLoading}>
        <ScrollBar
          style={{
            paddingRight: '16px',
            marginRight: '-16px',
            height: 'calc(100vh - 280px)',
          }}
        >
          {licenseTypes &&
            licenseTypes.map(lt => (
              <div
                className="listItemBordered"
                key={lt.guid}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                onClick={() => licenseTypeDetailClick(lt)}
              >
                <div>{lt.name}</div>
                <div>
                  <EditOutlined
                    style={{ color: '#1890ff', marginRight: '20px' }}
                    onClick={evt => {
                      evt.stopPropagation()
                      editTypeClick(lt)
                    }}
                  />
                  <DeleteFilled
                    style={{ color: 'red', marginRight: '10px' }}
                    onClick={evt => {
                      evt.stopPropagation()
                      deleteLicenseTypeClick(lt)
                    }}
                  />
                </div>
              </div>
            ))}
        </ScrollBar>
      </Spin>
      {pageMeta && (
        <div
          className="paginationBottom"
          style={{
            width: `calc(100% - ${leftMenuCollapsed ? '146' : '266'}px)`,
          }}
        >
          <Pagination
            showSizeChanger
            current={pageMeta.page}
            total={pageMeta.queryCount}
            pageSize={pageMeta.pageSize}
            onChange={changePage}
          />
        </div>
      )}
    </div>
  )
}
