import React, { useEffect } from 'react'
import { Button, Row, Typography, Collapse } from 'antd'
import { PlusCircleOutlined, CaretRightOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '~/Store/hooks'
import { getDefaultOrganization } from '~/Store/User/selectors'
import {
  OrganizationsService,
  OrgRoleItemResponse,
} from '~/Services/OrganizationService'
import { errorNotification } from '~/Services'
import styles from './Roles.module.less'
import { ScrollBar } from '~/Components/ScrollBar'
import { UserItemData } from '~/Services/UserService.d'
import { WaitOverlay } from '~/Components'
import { WaitOverlayActions } from '~/Store/Overlays'
import { RoleModal } from './RoleModal'
import { AssignRoleExtra } from './AssignRoleExtra'
import { RolePanelHeader } from './RolePanelHeader'
import { OrganizationActions } from '~/Store/Organization'
import {
  getOrganizationRoles,
  getRoleActivePanel,
  getRoleModalVisibility,
} from '~/Store/Organization/selectors'
import { UserList } from './UserList'
import { roleModalName, waitOverlayName } from './constant'

const { Panel } = Collapse

export const Roles = () => {
  const dispatch = useAppDispatch()
  const defaultOrg = useAppSelector(getDefaultOrganization)
  const orgRoles = useAppSelector(getOrganizationRoles)
  const roleModalVisibility = useAppSelector(
    getRoleModalVisibility(roleModalName),
  )
  const activeRole = useAppSelector(getRoleActivePanel)

  const fetchOrganizationRolesAndUsers = async () => {
    if (!defaultOrg) {
      return
    }
    try {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }),
      )
      const ps: Promise<any>[] = []
      ps.push(OrganizationsService.Get.getOrganizationRoles(defaultOrg.guid))
      ps.push(
        OrganizationsService.Get.getOrganizationUsers(defaultOrg.guid, {
          paging: false,
        }),
      )
      const res = await Promise.all(ps)
      if (res[0].data) {
        const roles = res[0].data as OrgRoleItemResponse[]
        dispatch(OrganizationActions.setOrganizationRoles(roles))
      }
      if (res[1].data) {
        const users = res[1].data as UserItemData[]
        dispatch(OrganizationActions.setOrganizationUsers(users))
      }
    } catch (error) {
      errorNotification(error)
    } finally {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }),
      )
    }
  }

  useEffect(() => {
    fetchOrganizationRolesAndUsers()
  }, [defaultOrg])

  const activeRoleCallback = (key: string[] | string | undefined) => {
    dispatch(OrganizationActions.setRoleActivePanel(key))
  }

  const openRoleModal = () => {
    dispatch(
      OrganizationActions.setModalState({ name: roleModalName, value: true }),
    )
  }

  return (
    <WaitOverlay name={waitOverlayName} size="large">
      {roleModalVisibility && <RoleModal />}
      <Row align="stretch">
        <Typography.Title style={{ flex: 1 }} level={4}>
          Roles Management
        </Typography.Title>
        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={openRoleModal}
        >
          New Role
        </Button>
      </Row>
      <div className={styles.organizationRoles} style={{ marginTop: '15px' }}>
        <ScrollBar style={{ paddingRight: '16px', marginRight: '-16px' }}>
          <Collapse
            activeKey={activeRole}
            onChange={activeRoleCallback}
            ghost
            className={styles.roleCollapse}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {orgRoles.map(role => (
              <Panel
                header={<RolePanelHeader role={role} />}
                key={role.id}
                className={styles.roleCollapsePanel}
                extra={<AssignRoleExtra role={role} />}
              >
                <UserList role={role} />
              </Panel>
            ))}
          </Collapse>
        </ScrollBar>
      </div>
    </WaitOverlay>
  )
}
