import React, { FC, SVGProps } from 'react'

export const SubmitSuccessSvg: FC<SVGProps<any>> = ({ height, width }) => (
  <svg
    width={width || 30}
    height={height || 30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_973_5528)">
      <path
        d="M15 0C6.72857 0 0 6.72857 0 15C0 23.2714 6.72857 30 15 30C23.2714 30 30 23.2714 30 15C30 6.72857 23.2714 0 15 0ZM24 10.0714C24 10.0714 16.1571 18.7286 14.4 20.7857C12.6429 22.8429 11.2714 20.7857 11.2714 20.7857L6.17143 15.5143C6.17143 15.5143 5.35714 14.2714 6.81429 13.1143C8.18571 12 9.3 13.1143 9.3 13.1143L12.9 16.8857L21.8571 8.52857C21.8571 8.52857 22.7143 7.92857 23.6143 8.7C24.3 9.34286 24 10.0714 24 10.0714Z"
        fill="#68BF7B"
      />
    </g>
    <defs>
      <clipPath id="clip0_973_5528">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
