import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Avatar, Row, Col, Typography, Button, ColProps } from 'antd'
import { Modal, ModalProps } from '~/Components/Modal'
import { UserData } from '~/Services/UserService.d'
import { getDefaultOrganization } from '~/Store/User/selectors'
import { Utils } from '~/Utils'
import { LicenseCards } from '~/Components/LicenseCards/LicenseCards'

const { Title } = Typography

interface RemoveUserProps extends Omit<ModalProps, 'onOk'> {
  user: Partial<UserData>
  licenses: any[]
  onOk?: (data: Partial<UserData>) => void
}

export const RemoveUser: FC<RemoveUserProps> = props => {
  const {
    onOk,
    onCancel,
    loadingData,
    confirmLoading,
    user,
    licenses,
    ...rest
  } = props
  const defaultOrg = useSelector(getDefaultOrganization)

  const colProps: ColProps = {
    xs: 24,
    md: 12,
  }

  const handleOk = () => {
    if (onOk) onOk({ ...user })
  }

  const fullname = Utils.combineStrings([user.firstname, user.lastname])

  return (
    <Modal
      visible
      title="User info"
      maskClosable={false}
      keyboard={false}
      loadingData={loadingData}
      onCancel={onCancel}
      onOk={handleOk}
      width={650}
      {...rest}
    >
      {user && defaultOrg && (
        <>
          <Row gutter={[16, 8]}>
            <Col xs={24} md={6}>
              <Avatar
                shape="square"
                size={140}
                src={user.avatarUrl}
                style={{
                  backgroundColor: user.avatarUrl
                    ? undefined
                    : Utils.stringToColour(user.id),
                }}
                gap={1}
              >
                {(fullname || user.email)?.charAt(0).toLocaleUpperCase()}
              </Avatar>
            </Col>
            <Col xs={24} md={18}>
              <Row gutter={8}>
                <Col span={24}>
                  <Row align="stretch">
                    <Title style={{ flex: 1, paddingTop: '4px' }} level={5}>
                      {fullname}
                      {defaultOrg.companyName && (
                        <> &bull;{` ${defaultOrg.companyName}`}</>
                      )}
                    </Title>
                    <Button
                      danger
                      loading={confirmLoading}
                      type="link"
                      onClick={handleOk}
                    >
                      Remove user
                    </Button>
                  </Row>
                </Col>
                <Col span={24}>{user.email}</Col>
                <Col span={24}>Product owner</Col>
                <Col span={24}>{user.phoneNumber}</Col>
                <Col span={24}>{user.invitationStatus}</Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ marginTop: '25px' }}>
            <Col span={24}>
              <Title level={5}>Personal address</Title>
            </Col>
            <Col span={24}>{user.profile?.address}</Col>
            <Col span={24}>{user.profile?.address2}</Col>
          </Row>
          <Row gutter={[8, 8]} style={{ marginTop: '25px' }}>
            <Col span={24}>
              <Title level={5}>Company address</Title>
            </Col>
            <Col span={24}>
              {Utils.combineStrings(
                [
                  defaultOrg.billingAddress,
                  defaultOrg.billingAddress2,
                  defaultOrg.billingCity,
                  defaultOrg.billingState,
                  defaultOrg.billingZip,
                ],
                ', ',
              )}
            </Col>
            <Col span={24} />
          </Row>
          {licenses.length > 0 && (
            <Row gutter={[8, 8]} style={{ marginTop: '25px' }}>
              <Col span={24}>
                <Title level={5}>Licenses</Title>
              </Col>
              {licenses.map(l => (
                <LicenseCards key={l.guid} license={l} col={colProps} />
              ))}
            </Row>
          )}
        </>
      )}
    </Modal>
  )
}
