import React, { useEffect, useState } from 'react'
import { Col, Layout, Row, Typography } from 'antd'
import { useLocation } from 'react-router-dom'
import { AxiosError } from 'axios'
import { HiCasLogoSvg, StatusCard, StatusCardState } from '../../../Components'
import style from './ConfirmationView.module.less'
import { UserService } from '~/Services'

const { Put } = UserService
const { Title, Paragraph } = Typography
const { Content, Sider } = Layout

export const ConfirmationHicas = () => {
  const location = useLocation()
  const [status, setStatus] = useState({
    status: StatusCardState.Waiting,
    title: 'Vui lòng đợi trong khi chúng tôi xác nhận tài khoản của bạn',
    body: 'Tài khoản của bạn đang được xác minh. Vui lòng chờ ít phút!',
  })
  const setSuccessState = ({ title, body }: { [key: string]: string } = {}) => {
    setStatus({
      status: StatusCardState.Success,
      title: title || 'Bạn đã xác nhận tài khoản thành công!',
      body:
        body ||
        'Bây giờ bạn có thể đóng tab này và quay lại ứng dụng để đăng nhập. Thao tác này sẽ cấp cho bạn quyền truy cập vào tất cả các tính năng',
    })
  }
  const setErrorState = ({ title, body }: { [key: string]: string } = {}) => {
    setStatus({
      status: StatusCardState.Error,
      title: title || 'Có vẻ như đã xảy ra sự cố',
      body:
        body ||
        'Vui lòng thử xác nhận lại bằng cách nhấp vào liên kết hoặc đăng ký lại tài khoản của bạn bên trong ứng dụng',
    })
  }
  const verifyEmail = async (): Promise<void> => {
    const params = new URLSearchParams(location.search)
    const cb = params.get('cb')

    if (!cb) {
      setErrorState({
        body: 'Có vẻ như bạn đã thiếu một vài thông tin! Nếu bạn đã sao chép và dán từ email của mình, hãy đảm bảo rằng bạn đã sao chép toàn bộ liên kết !',
      })
    } else {
      try {
        await Put.confirmUserEmail(cb)
        setSuccessState()
      } catch (err: AxiosError | any) {
        if (err.response) {
          if (err.response.status === 404) {
            setErrorState()
          } else {
            setErrorState()
          }
        } else {
          setErrorState()
        }
      }
    }
  }

  useEffect(() => {
    verifyEmail()
  }, [])

  return (
    <Layout style={{ height: '100vh', backgroundColor: '#fff' }}>
      <Sider style={{ backgroundColor: '#fff' }} />
      <Content style={{ margin: 24 }}>
        <Row>
          <HiCasLogoSvg width={130} height={50} />
        </Row>
        <Row gutter={48} justify="space-around" align="middle">
          <Col span={12}>
            <Title level={1}>{status.title || ''}</Title>
            <Paragraph>{status.body}</Paragraph>
          </Col>
          <Col span={12}>
            <StatusCard state={status.status} />
          </Col>
        </Row>
      </Content>
      <Sider style={{ backgroundColor: '#fff' }} />
    </Layout>
  )
}
