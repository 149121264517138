import { createSlice } from '@reduxjs/toolkit'
import { Buffer } from 'buffer'
import { UserData, UserPreferencesData } from '~/Services/UserService.d'
import { Utils } from '~/Utils'

export interface UserReducerStore {
  firstName: string
  lastName: string
  token: string
  refreshToken: string
  userName: string
  email: string
  id: string
  authenticated: boolean
  authWithOrg: boolean
  avatarUrl: string
  roles: string[]
  orgRoles: string[]
  current?: UserData
  organizations: { [key: string]: any }[]
  preferences?: UserPreferencesData
  defaultOrganization?: { [key: string]: any }
  licenses: { [key: string]: any }[]
  editState: boolean
  loading: boolean
}

const initialState: UserReducerStore = {
  firstName: '',
  lastName: '',
  token: '',
  refreshToken: '',
  userName: '',
  email: '',
  id: '',
  authenticated: false,
  authWithOrg: false,
  avatarUrl: '',
  roles: [],
  orgRoles: [],
  organizations: [],
  licenses: [],
  editState: false,
  loading: false,
}

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      const token = action.payload
      const [_header, payload, _signature] = token.split('.')
      try {
        const decodedProfile = Buffer.from(payload, 'base64').toString()
        const { profile, orgRoles, role } = JSON.parse(decodedProfile)
        const { Firstname, Lastname, UserName, Email, Id } = JSON.parse(profile)

        state.firstName = Firstname
        state.lastName = Lastname
        state.userName = UserName
        state.email = Email
        state.id = Id
        state.orgRoles = orgRoles || []
        state.roles = role || []
      } catch (err) {
        console.log('There was an error in decoding your profile')
      }
      state.token = action.payload
      state.authenticated = true
      Utils.setLocalStorage('token', action.payload)
    },
    setAuthenticatedWithOrg: (state, action) => {
      state.authWithOrg = action.payload
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload
    },
    setAvatarUrl: (state, action) => {
      state.avatarUrl = action.payload
    },
    setEditState: (state, action) => {
      state.editState = action.payload
    },
    logout: () => {
      localStorage.clear()
      return initialState
    },
    setCurrentUser: (state, action) => {
      delete action.payload.password
      state.current = action.payload
    },
    setUserOrganizations: (state, action) => {
      state.organizations = action.payload
    },
    setUserPreferences: (state, action) => {
      state.preferences = action.payload
    },
    setDefaultOrganization: (state, action) => {
      state.defaultOrganization = action.payload
    },
    setUserLicenses: (state, action) => {
      state.licenses = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    updateUserProfileInfo: (state, action) => {
      state.loading = true
    },
    updateUserProfileInfoSuccess: (state, action) => {
      const { payload } = action
      const { id, firstname, lastname, userName, email, avatarUrl } = payload
      state.id = id
      state.firstName = firstname
      state.lastName = lastname
      state.userName = userName
      state.email = email
      state.avatarUrl = avatarUrl
      state.current = payload
      state.loading = false
      state.editState = false
    },
    updateUserProfileInfoFail: state => {
      state.loading = false
    },
    getMyOrganizations: state => {
      state.loading = true
    },
    getMyOrganizationsSuccess: (state, action) => {
      state.organizations = action.payload
      state.loading = false
    },
    getMyOrganizationsFail: state => {
      state.loading = false
    },
  },
})

export const UserActions = UserSlice.actions
export const UserReducer = UserSlice.reducer
