import React, { useState, useEffect } from 'react'
import { Modal, Form, Spin, Select, Button, Typography, Row, Col } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '~/Store'
import {
  getLoading,
  getModalVisibility,
  getUsersToInvite,
} from '~/Store/Organization/selectors'
import { OrganizationActions, OrgModelName } from '~/Store/Organization'
import { NoData } from '~/Components/NoData'
import { Utils } from '~/Utils'
import { UserItemMeta } from '~/Components/UserItemMeta'
import { UserItemData } from '~/Services/UserService.d'
import { UserList } from '~/Components/UserList'
import { getDefaultOrganization } from '~/Store/User/selectors'

const { Option } = Select

export const InviteUser = () => {
  const dispatch = useAppDispatch()
  const visibility = useAppSelector(
    getModalVisibility(OrgModelName.inviteUserVisibility),
  )
  const fetching = useAppSelector(getLoading)
  const listUsers = useAppSelector(getUsersToInvite)
  const defaultOrg = useAppSelector(getDefaultOrganization)
  const confirmInviteModal = useAppSelector(
    getModalVisibility(OrgModelName.confirmInviteModal),
  )
  const [timer, setTimer] = useState<any>(null)
  const [filterString, setFilterString] = useState('')
  const [form] = Form.useForm()
  const [inviteList, setInviteList] = useState<UserItemData[]>([])

  useEffect(() => {
    setInviteList([])
    if (form.__INTERNAL__.name) {
      form.resetFields()
    }
  }, [visibility])

  const closeSelf = () => {
    form.resetFields()
    dispatch(
      OrganizationActions.setModalState({
        name: OrgModelName.inviteUserVisibility,
        value: false,
      }),
    )
  }

  const fetchingUsers = (findString: string) => {
    clearTimeout(timer)
    const timeoutId = setTimeout(() => {
      setFilterString(findString)
      if (!findString) {
        dispatch(OrganizationActions.filterUsersToInviteSuccess([]))
      } else {
        const findQuery = `contains(userName, '${findString}') or contains(email, '${findString}')`
        dispatch(
          OrganizationActions.filterUsersToInvite({
            filter: findQuery,
            NotInOrgId: defaultOrg!.guid,
            paging: false,
          }),
        )
      }
    }, 800)
    setTimer(timeoutId)
  }

  const inviteUsersToOrg = (andNewUser = false) => {
    if (!defaultOrg || inviteList.length === 0) {
      return
    }
    const orgId: string = defaultOrg.guid
    const userIds: string[] = inviteList.map(i => i.id)
    dispatch(
      OrganizationActions.inviteUsersToOrg({ orgId, userIds, andNewUser }),
    )
  }

  const addUsersToInvite = (values: any) => {
    const { userEmail } = values
    const user = listUsers.find(i => i.email === userEmail)
    if (user && !inviteList.find(i => i.email === userEmail)) {
      setInviteList([...inviteList, user])
    }
    form.resetFields()
    setFilterString('')
    dispatch(OrganizationActions.filterUsersToInviteSuccess([]))
  }

  const showNewUserForm = () => {
    if (inviteList.length > 0) {
      dispatch(
        OrganizationActions.setModalState({
          name: OrgModelName.confirmInviteModal,
          value: true,
        }),
      )
    } else {
      dispatch(OrganizationActions.showNewUserModal())
    }
  }

  const justCreateNewUserBtn = () => {
    setInviteList([])
    form.resetFields()
    dispatch(OrganizationActions.showNewUserModal())
  }

  const cancelConfirmModal = () => {
    dispatch(
      OrganizationActions.setModalState({
        name: OrgModelName.confirmInviteModal,
        value: false,
      }),
    )
  }

  return (
    <>
      <Modal
        visible={confirmInviteModal}
        closable={false}
        footer={[
          <Button key="cancelBtn" onClick={cancelConfirmModal}>
            Cancel
          </Button>,
          <Button key="noBtn" onClick={justCreateNewUserBtn}>
            No, just create new
          </Button>,
          <Button key="okBtn" onClick={() => inviteUsersToOrg(true)}>
            OK
          </Button>,
        ]}
        width={400}
      >
        <div style={{ padding: '16px 16px 8px' }}>
          <div className="ant-modal-confirm-body">
            <InfoCircleOutlined
              style={{ color: 'orange', marginRight: '10px' }}
            />
            <span className="ant-modal-confirm-title">{`Do you want to invite (${inviteList.length}) users selected to the organization before continue create new user?`}</span>
            {/* <div className="ant-modal-confirm-content"></div> */}
          </div>
        </div>
      </Modal>
      <Modal
        visible={visibility}
        onCancel={closeSelf}
        title="Invite users"
        footer={null}
      >
        {inviteList.length > 0 && (
          <div
            style={{ borderBottom: '1px solid #f0f0f0', marginBottom: '10px' }}
          >
            <UserList users={inviteList} />
          </div>
        )}
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          initialValues={{ userEmail: '' }}
          onFinish={addUsersToInvite}
          name="InviteUserForm"
        >
          <Row align="stretch" style={{ marginBottom: '10px' }}>
            <Typography.Text style={{ flex: 1 }}>User email</Typography.Text>
            <Typography.Link
              style={{ fontWeight: 'bold' }}
              onClick={showNewUserForm}
            >
              New User
            </Typography.Link>
          </Row>
          <Form.Item
            name="userEmail"
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Select
              placeholder="example@gmail.com"
              showSearch
              showArrow={false}
              optionLabelProp="label"
              onSearch={fetchingUsers}
              notFoundContent={
                fetching ? (
                  <Spin size="small">
                    <div style={{ width: '100%', padding: '20px' }} />
                  </Spin>
                ) : (
                  <div style={{ padding: '20px' }}>
                    {filterString && listUsers.length === 0 ? (
                      <NoData content="Not found" />
                    ) : (
                      <NoData content="Input search text" />
                    )}
                  </div>
                )
              }
              autoFocus
            >
              {listUsers.map(item => (
                <Option
                  value={item.email}
                  label={
                    Utils.combineStrings([item.firstname, item.lastname]) ||
                    item.email
                  }
                  key={item.id}
                >
                  <UserItemMeta user={item} />
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                {inviteList.length > 0 ? (
                  <>
                    <Button type="link" htmlType="submit" disabled={fetching}>
                      Invite more users
                    </Button>
                    <Button
                      shape="round"
                      type="primary"
                      style={{ marginLeft: '8px' }}
                      loading={fetching}
                      onClick={() => inviteUsersToOrg()}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <Button shape="round" type="primary" htmlType="submit">
                    Invite users
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
