import React from 'react';
import { useSelector } from 'react-redux';
import { PageHeader as AntdPageHeader } from 'antd';
import { UserLogin } from './UserLogin';
import { LayoutSelectors } from '~/Store/Layout';
import style from './PageHeader.module.less';
import { Notifications } from './Notifications';

export const PageHeader = () => {
  const title = useSelector(LayoutSelectors.getDocumentTitle);

  return (
    <AntdPageHeader
      title={title}
      className={style.PageHeader}
      extra={
        <div
          className="ant-space ant-space-horizontal ant-space-align-center"
          style={{ gap: '8px' }}
        >
          <div className="ant-space-item">
            <Notifications />
          </div>
          <div className="ant-space-item">
            <UserLogin />
          </div>
        </div>
      }
    />
  );
};
