import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { Delete } from './Delete'
import { Filter } from './Devices'
import { Get } from './Get'
import { UserProfileObject } from './organization.types'
import { Post } from './Post'
import { Put } from './Put'
import { UserItemData } from './UserService.d'

export interface UpdateInfoParams {
  companyName: string
  billingAddress: string
  billingAddress2: string
  billingCity: string
  billingState: string
  billingZip: string
  crmId: string
  duns: string
  naics: string
  guid?: string
}
interface UpdateRoleParams {
  roleName: string
  newRoleName: string
}
interface UserFilter {
  filter?: string
  expand?: string
  paging?: boolean
  orderBy?: string
  page?: number
  pageSize?: number
  offset?: number
  take?: number
  invitationStatus?: string
  roles?: string
}

export interface OrgRoleItemResponse {
  id: string
  name: string
  normalizedName: string
  organizationId: string
  allowRemoveUser?: boolean
  users?: UserItemData[]
  allowEdit?: boolean
}

export interface RemoveRoleFromUsers {
  id: string
  organizationRoles: OrgRoleItemResponse
  roleId: string
  roleName: string | null
  userOrganizationId: string
}
class OrganizationServiceController {
  private licenseManagerUrl =
    process.env.LICENSE_MANAGER_URL ??
    window.location.protocol +
      '//' +
      window.location.hostname +
      '/license_manager'

  private organizationServerUrl = `${this.licenseManagerUrl}/organizations`

  public Put = {
    updateOrganizationsInfo: (data: UpdateInfoParams) =>
      Put(this.organizationServerUrl, data),
    updateOrganizationInfo: (id: string, data: Partial<UpdateInfoParams>) => {
      const url = `${this.organizationServerUrl}/${id}`
      return Put(url, data)
    },
    updateOrganizationRole: (id: string, data: UpdateRoleParams) => {
      const url = `${this.organizationServerUrl}/${id}/organizationRoles`
      return Put(url, data)
    },
  }

  public Get = {
    getOrganizationUsers: async (
      orgId: string,
      data?: UserFilter,
      roles?: string[],
      invitationStatus?: string[],
    ) => {
      let url = `${this.organizationServerUrl}/${orgId}/users`
      let rolesParam = ''
      if (roles) {
        rolesParam = `roles=${roles.join('&roles=')}`
      }
      let statusParam = ''
      if (invitationStatus) {
        statusParam = `invitationStatus=${invitationStatus.join(
          '&invitationStatus=',
        )}`
      }
      if (rolesParam || statusParam) {
        url += `?${[rolesParam, statusParam].filter(i => i).join('&')}`
      }

      const filterData = {
        params: {
          ...data,
        },
      }
      return Get(url, filterData)
    },
    searchOrganizationUsers: (data: UserFilter) => {
      const filterData = {
        params: {
          ...data,
        },
      }
      return Get(this.organizationServerUrl, filterData)
    },
    getOrganization: (orgId: string) => {
      const url = `${this.organizationServerUrl}/${orgId}`
      return Get(url)
    },
    getOrganizationLicense: (orgId: string) => {
      const url = `${this.organizationServerUrl}/${orgId}/licenses`
      return Get(url)
    },
    getOrganizationRoles: (
      orgId: string,
    ): AxiosPromise<OrgRoleItemResponse[] | AxiosError> => {
      const url = `${this.organizationServerUrl}/${orgId}/organizationRoles`
      return Get(url)
    },
    getOrganizationAdmin: (orgId: string) => {
      const url = `${this.organizationServerUrl}/${orgId}/administrators`
      return Get(url)
    },
    getUsersToInvite: async (data: Filter) => {
      const filterData = { params: { ...data } }
      return Get(`${this.licenseManagerUrl}/users`, filterData)
    },
  }

  public Post = {
    addOrganizationAdmin: (orgId: string, adminId: string) => {
      const url = `${this.organizationServerUrl}/${orgId}/administrators`
      return Post(url, adminId)
    },
    addOrganizationUser: async (orgId: string, userIds: string[]) => {
      const url = `${this.organizationServerUrl}/${orgId}/users`
      return Post(url, userIds)
    },
    createOrganizations: (data: UpdateInfoParams) =>
      Post(this.organizationServerUrl, data),
    createOrganizationRole: (orgId: string, newRoles: string[]) => {
      const url = `${this.organizationServerUrl}/${orgId}/organizationRoles`
      return Post(url, newRoles)
    },
    changeUserStatus: (
      orgId: string,
      data: { email: string; status: string }[],
    ) =>
      Post(`${this.organizationServerUrl}/${orgId}/notify/userinvites`, data),
    addOrgLicenseToUsers: (userId: string, licenseIds: string[]) =>
      Post(
        `${this.organizationServerUrl}/users/${userId}/licenses`,
        licenseIds,
      ),
    addOrganizationRolesToUser: (
      userId: string,
      roleData: { organizationId: string; roleName: string }[],
    ) =>
      Post(
        `${this.licenseManagerUrl}/users/${userId}/organizationRoles`,
        roleData,
      ),
    addUserToOrganization: (data: UserProfileObject) =>
      Post(`${this.organizationServerUrl}/users/register`, data),
  }

  public Delete = {
    removeUsers: (orgId: string, userIds: string[]) => {
      const url = `${this.organizationServerUrl}/${orgId}/users`
      return Delete(url, userIds)
    },
    removeUsersFromOrgLicense: (
      userId: string,
      licenseIds: string[],
    ): AxiosPromise<AxiosResponse<number> | AxiosError> =>
      Delete(
        `${this.organizationServerUrl}/users/${userId}/licenses`,
        licenseIds,
      ),
    removeOrganizationRolesFromUser: (
      userId: string,
      roleData: { organizationId: string; roleName: string }[],
    ): AxiosPromise<AxiosResponse<RemoveRoleFromUsers[]> | AxiosError> =>
      Delete(
        `${this.licenseManagerUrl}/users/${userId}/organizationRoles`,
        roleData,
      ),
    removeRoleFromOrganization: (
      organizationId: string,
      roles: string[],
    ): AxiosPromise<AxiosResponse<OrgRoleItemResponse> | AxiosError> =>
      Delete(
        `${this.organizationServerUrl}/${organizationId}/organizationRoles`,
        roles,
      ),
  }
}

export const OrganizationsService = new OrganizationServiceController()
