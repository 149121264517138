import { Collapse, List } from 'antd'
import React, { useState, useEffect } from 'react'
import { LicenseAvatar } from '~/Components/LicenseAvatar'
import { Modal } from '~/Components/Modal'
import { ScrollBar } from '~/Components/ScrollBar'
import { errorNotification, LicenseService } from '~/Services'
import { ILicenseTypeDetail } from '~/Services/License.interface'
import { useAppDispatch, useAppSelector } from '~/Store'
import {
  getLicenseTypeSelected,
  getModalVisibility,
  LicenseMgmtActions,
  ModalName,
} from '~/Store/License'

const { Panel } = Collapse

export const LicenseTypeDetail = () => {
  const dispatch = useAppDispatch()
  const licenseType = useAppSelector(getLicenseTypeSelected)
  const visibility = useAppSelector(
    getModalVisibility(ModalName.LicenseTypeDetail),
  )
  const [isLoading, setIsLoading] = useState(false)
  const [licenseTypeDetail, setLicenseTypeDetail] =
    useState<ILicenseTypeDetail>()

  useEffect(() => {
    if (licenseType) {
      getLicenseTypeById(licenseType.guid)
    }
  }, [licenseType])

  const getLicenseTypeById = async (guid: string) => {
    try {
      setIsLoading(true)
      const typeRes = await LicenseService.Get.getLicenseInfoById(guid)
      const typeData: ILicenseTypeDetail = typeRes.data
      setLicenseTypeDetail(typeData)
    } catch (error) {
      errorNotification(error)
    } finally {
      setIsLoading(false)
    }
  }

  const selfClose = () => {
    dispatch(
      LicenseMgmtActions.setModalVisibility({
        name: ModalName.LicenseTypeDetail,
        visible: false,
      }),
    )
  }

  return (
    <Modal
      visible={visibility}
      loadingData={isLoading}
      title="License Type Info"
      onCancel={selfClose}
      footer={null}
    >
      {licenseTypeDetail && (
        <div>
          <List.Item.Meta
            avatar={
              <LicenseAvatar
                type={licenseTypeDetail.type}
                height={50}
                width={50}
              />
            }
            title={
              <>
                <span style={{ fontWeight: 'bolder' }}>
                  {licenseTypeDetail.name}
                </span>
              </>
            }
            description={`${licenseTypeDetail.type}`}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ marginTop: '10px' }}>
              <ScrollBar
                style={{
                  maxHeight: 'calc(100vh - 300px)',
                  paddingRight: '16px',
                  marginRight: '-16px',
                  paddingBottom: '16px',
                  marginBottom: '-16px',
                }}
              >
                {licenseTypeDetail.products && (
                  <Collapse
                    defaultActiveKey={licenseTypeDetail.products[0].guid}
                  >
                    {licenseTypeDetail.products.map(p => (
                      <Panel header={p.name} key={p.guid}>
                        {p.claims.map(c => (
                          <div key={c.guid}>{`${c.claimValue}`}</div>
                        ))}
                      </Panel>
                    ))}
                  </Collapse>
                )}
              </ScrollBar>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}
