import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DeactivateDeviceData, Filter } from '~/Services/Devices'
import { UserData } from '~/Services/UserService.d'
export interface Device {
  id: string
  userId: string
  user: UserData
  isActive: boolean
  clientId: string
  deviceId: string
  device: {
    guid: string
    name: string
    mac: string
    flatform: string
    location: string
    information: string
    position: string
  }
  createTime: Date
  lastActionTime: Date
  activeTime: Date
  version: string
}

interface SearchState {
  status: string
  filterString: string
}
export interface DevicesStore {
  listDevice: Device[]
  filter: Filter
  searchState: SearchState
  loading: boolean
}

const initialState: DevicesStore = {
  listDevice: [],
  filter: {
    page: 1,
    pageSize: 10,
    paging: true,
    total: 10,
  },
  searchState: {
    status: '',
    filterString: '',
  },
  loading: false,
}

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    getListDevices: (state, action: PayloadAction<Filter>) => {
      const { payload } = action
      state.loading = true
      state.filter = { ...state.filter, ...payload }
    },
    getListDevicesSuccess: (state, action) => {
      const { payload } = action
      state.listDevice = payload['results']
      state.filter.pageSize = payload['pageSize']
      state.filter.page = payload['page']
      state.filter.total = payload['queryCount']
      state.loading = false
    },
    getListDevicesFail: state => {
      state.loading = false
    },
    setSearchState: (state, action: PayloadAction<SearchState>) => {
      state.searchState = action.payload
    },
    deactivateDevices: (
      state,
      action: PayloadAction<DeactivateDeviceData[]>,
    ) => {
      state.loading = true
    },
    deactivateDevicesFail: state => {
      state.loading = false
    },
    deactivateDevicesSuccess: (state, action) => {
      const { payload } = action
      payload.forEach((d: Device) => {
        const idexItem = state.listDevice.findIndex(x => x.id === d.id)
        if (idexItem !== -1) {
          state.listDevice[idexItem] = d
        }
      })
      state.loading = false
    },
  },
})

export const DevicesActions = devicesSlice.actions
export const DevicesReducer = devicesSlice.reducer
