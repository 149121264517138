import React, { FC } from 'react'
import { Avatar, Col, Row, Tag, Typography, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { UserInviteStatus } from '~/Services/UserService.d'
import { LicenseData, OrganizationActions } from '~/Store/Organization'
import { Utils } from '~/Utils'
import styles from './Info.module.less'
import { useAppDispatch, useAppSelector } from '~/Store'
import { getCurrentUser, getDefaultOrganization } from '~/Store/User/selectors'

export interface OrganizationItemData {
  billingAddress: string
  billingAddress2: string
  billingCity: string
  billingState: string
  billingZip: string
  companyName: string
  crmId?: string
  duns?: string
  guid: string
  invitationStatus: string
  licenses: LicenseData[]
  naics?: string
  avatarUrl?: string
}
interface OrganizationItemProps {
  org: OrganizationItemData
  licenseName: string
}

export const OrganizationItem: FC<OrganizationItemProps> = ({
  org,
  licenseName,
}) => {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUser)
  const defaultOrg = useAppSelector(getDefaultOrganization)
  const isPending = org.invitationStatus === UserInviteStatus.Pending
  const isDeclined = org.invitationStatus === UserInviteStatus.Declined

  const leaveOrganization = () => {
    Modal.confirm({
      title: `Do you want to leave the ${org.companyName} organization?`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <div>{`Address: ${Utils.combineStrings(
            [
              org.billingAddress,
              org.billingAddress2,
              org.billingState,
              org.billingCity,
              org.billingZip,
            ],
            ', ',
          )}`}</div>
        </>
      ),
      onOk: () => {
        dispatch(
          OrganizationActions.removeUsersFromOrganization({
            orgId: org.guid,
            userIds: [currentUser?.id],
          }),
        )
      },
    })
  }

  return (
    <Col xl={12} xs={24}>
      <Row
        align="middle"
        className={styles.OrgRow}
        style={{
          cursor: isPending || isDeclined ? 'not-allowed' : 'default',
          backgroundColor: isPending || isDeclined ? '#f5f5f5' : 'transparent',
        }}
      >
        <Col className={styles.OrganizationItemAvatar}>
          <Avatar
            shape="square"
            size={50}
            src={org.avatarUrl}
            style={{
              backgroundColor: org.avatarUrl
                ? undefined
                : Utils.stringToColour(org.guid),
            }}
          >
            {org.companyName.charAt(0).toLocaleUpperCase()}
          </Avatar>
        </Col>
        <Col style={{ width: 'calc(100% - 70px)' }}>
          <Row
            style={{
              color: isDeclined ? '#00000040' : '#666989',
            }}
            align="stretch"
          >
            <strong style={{ flex: 1 }}>{org.companyName}</strong>
            {licenseName !== '' && (
              <div className={styles.InivationStatus}>
                <Tag color={isPending ? '#00000040' : 'green'}>
                  {licenseName}
                </Tag>
              </div>
            )}
            {defaultOrg && defaultOrg.guid !== org.guid ? (
              <Typography.Link type="danger" onClick={leaveOrganization}>
                Leave
              </Typography.Link>
            ) : (
              <Tag color="blue">Default</Tag>
            )}
          </Row>
          <Row style={{ color: isDeclined ? '#00000040' : '#666989' }}>
            {org.billingAddress}
          </Row>
        </Col>
      </Row>
    </Col>
  )
}
