import React, { FC } from 'react'
import { List, Modal } from 'antd'
import {
  EditOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import {
  OrganizationsService,
  OrgRoleItemResponse,
} from '~/Services/OrganizationService'
import { errorNotification } from '~/Services'
import { useAppDispatch, useAppSelector } from '~/Store/hooks'
import { getDefaultOrganization } from '~/Store/User/selectors'
import { getOrganizationUsersByRole } from '~/Store/Organization/selectors'
import { OrganizationActions } from '~/Store/Organization'
import { WaitOverlayActions } from '~/Store/Overlays'
import { OrgClaim } from '../../Routes'
import { roleModalName, waitOverlayName } from './constant'

const { confirm } = Modal
interface RolePanelHeaderProps {
  role: OrgRoleItemResponse
}

export const RolePanelHeader: FC<RolePanelHeaderProps> = ({ role }) => {
  const dispatch = useAppDispatch()
  const defaultOrg = useAppSelector(getDefaultOrganization)
  const orgUsers = useAppSelector(state =>
    getOrganizationUsersByRole(state, role.name),
  )

  const deleteRole = async (roleName: string) => {
    try {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }),
      )
      await OrganizationsService.Delete.removeRoleFromOrganization(
        defaultOrg?.guid,
        [roleName],
      )
      // reftech organization roles
      const res = await OrganizationsService.Get.getOrganizationRoles(
        defaultOrg?.guid,
      )
      if (res.data) {
        dispatch(
          OrganizationActions.setOrganizationRoles(
            res.data as OrgRoleItemResponse[],
          ),
        )
      }
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }),
      )
    } catch (error) {
      errorNotification(error)
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }),
      )
    }
  }

  const showDeleteConfirm = (roleName: string) => {
    confirm({
      title: `Are you sure delete the ${roleName} role?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteRole(roleName)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const openEditRoleModal = (role: OrgRoleItemResponse) => {
    dispatch(OrganizationActions.setOrganizationRoleSelected(role))
    dispatch(
      OrganizationActions.setModalState({ name: roleModalName, value: true }),
    )
  }

  return (
    <div style={{ display: 'inline-flex', width: '80%' }}>
      <List.Item.Meta
        title={
          <>
            <span style={{ fontWeight: 'bolder' }}>{role.name}</span>
            {![OrgClaim.Admin, OrgClaim.Superuser, OrgClaim.Member].includes(
              role.name as OrgClaim,
            ) && (
              <>
                <EditOutlined
                  style={{ marginLeft: '20px', color: '#1890ff' }}
                  data-id={role.id}
                  onClick={event => {
                    openEditRoleModal(role)
                    event.stopPropagation()
                  }}
                />
                <DeleteFilled
                  style={{ marginLeft: '10px', color: 'red' }}
                  onClick={event => {
                    showDeleteConfirm(role.name)
                    event.stopPropagation()
                  }}
                />
              </>
            )}
          </>
        }
        description={`Assigned to ${orgUsers.length} users`}
      />
    </div>
  )
}
