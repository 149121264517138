import { from } from 'rxjs'
import { catchError, filter, switchMap, map } from 'rxjs/operators'
import { RootEpic } from '~/Store/types'
import { errorNotification, UserService } from '~/Services'
import { UserActions } from './slice'

const updateUserProfileInfoEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(UserActions.updateUserProfileInfo.match),
    switchMap(action => {
      const { payload } = action
      return from(UserService.Put.updateUserProfile(payload)).pipe(
        map(res => UserActions.updateUserProfileInfoSuccess(res.data)),
        catchError(error => {
          errorNotification(error)
          return [UserActions.updateUserProfileInfoFail()]
        }),
      )
    }),
  )

const getMyOrganizationsEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(UserActions.getMyOrganizations.match),
    switchMap(action => {
      // const { payload } = action
      return from(UserService.Get.fetchCurrentUserOrganizations()).pipe(
        map(res => UserActions.getMyOrganizationsSuccess(res.data)),
        catchError(error => {
          errorNotification(error)
          return [UserActions.getMyOrganizationsFail()]
        }),
      )
    }),
  )

export const UserEpics = [updateUserProfileInfoEpic, getMyOrganizationsEpic]
