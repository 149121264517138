import React from 'react'
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '~/Store'
import { UserActions } from '~/Store/User'
import { getCurrentUser, getUserLoading } from '~/Store/User/selectors'
import { ScrollBar } from '~/Components/ScrollBar'

const { Title, Link } = Typography
const { Item } = Form

export const EditProfile = () => {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUser)
  const loading = useAppSelector(getUserLoading)

  const cancelEditProfile = () => {
    dispatch(UserActions.setEditState(false))
  }

  const saveChanges = (values: any) => {
    const profileData = {
      ...currentUser,
      firstname: values.firstname,
      lastname: values.lastname,
      profile: {
        ...currentUser?.profile,
        gender: values.gender,
        address: values.address,
        address2: values.address2,
        country: values.country,
        city: values.city,
        state: values.state,
        zip: values.zip,
        workAddress: values.workAddress,
        workAddress2: values.workAddress2,
        workCountry: values.workCountry,
        workCity: values.workCity,
        workState: values.workState,
        workZip: values.workZip,
      },
    }
    dispatch(UserActions.updateUserProfileInfo(profileData))
  }

  return (
    <>
      <Form
        layout="vertical"
        initialValues={{
          ...currentUser,
          ...currentUser?.profile,
        }}
        onFinish={saveChanges}
      >
        <Row align="stretch">
          <Title style={{ flex: 1 }} level={4}>
            Personal Information
          </Title>
          <>
            <Button
              danger
              style={{ marginRight: '5px' }}
              onClick={cancelEditProfile}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" icon={<SaveOutlined />}>
              Save Changes
            </Button>
          </>
        </Row>
        <Row>
          <Col md={24}>
            <ScrollBar
              style={{
                height: 'calc(100vh - 230px)',
                paddingRight: '16px',
                marginRight: '-16px',
              }}
            >
              <Spin spinning={loading}>
                <Row style={{ marginBottom: '15px' }}>
                  <Avatar shape="square" size="large" />
                  <Link style={{ marginLeft: '10px', marginTop: '10px' }}>
                    Change avatar
                  </Link>
                </Row>
                <Item label="Name" noStyle>
                  <Item
                    name="firstname"
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 8px)',
                    }}
                    rules={[
                      { required: true, message: 'First name is required' },
                    ]}
                  >
                    <Input placeholder="First name" />
                  </Item>
                  <Item
                    name="lastname"
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 0px)',
                      margin: '0 0 0 8px',
                    }}
                    rules={[
                      { required: true, message: 'Last name is required' },
                    ]}
                  >
                    <Input placeholder="Last name" />
                  </Item>
                </Item>
                <Item
                  label="Gender"
                  name="gender"
                  style={{ marginBottom: '8px' }}
                >
                  <Select>
                    <Select.Option value="Male">Male</Select.Option>
                    <Select.Option value="Female">Female</Select.Option>
                    <Select.Option value="">Unknown</Select.Option>
                  </Select>
                </Item>
                <Item
                  label="Personal Address"
                  style={{ marginBottom: 0, fontWeight: 'bold' }}
                >
                  <Item name="address" style={{ marginBottom: '8px' }}>
                    <Input placeholder="Street address" />
                  </Item>
                  <Item name="address2" style={{ marginBottom: '8px' }}>
                    <Input placeholder="Street address line 2" />
                  </Item>
                </Item>
                <Item style={{ marginBottom: 0 }}>
                  <Item
                    label="Country"
                    name="country"
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 8px)',
                      marginBottom: '8px',
                    }}
                  >
                    <Input placeholder="Country" />
                  </Item>
                  <Item
                    label="City"
                    name="city"
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 0px)',
                      margin: '0 0 0 8px',
                    }}
                  >
                    <Input placeholder="City" />
                  </Item>
                </Item>
                <Item style={{ marginBottom: 0 }}>
                  <Item
                    label="State"
                    name="state"
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 8px)',
                    }}
                  >
                    <Input placeholder="State" />
                  </Item>
                  <Item
                    label="Zip"
                    name="zip"
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 0px)',
                      margin: '0 0 0 8px',
                    }}
                  >
                    <Input placeholder="Zip" />
                  </Item>
                </Item>
                <Item
                  label="Work Address"
                  style={{ marginBottom: 0, fontWeight: 'bold' }}
                >
                  <Item name="workAddress" style={{ marginBottom: '8px' }}>
                    <Input placeholder="Street address" />
                  </Item>
                  <Item name="workAddress2" style={{ marginBottom: '8px' }}>
                    <Input placeholder="Street address line 2" />
                  </Item>
                </Item>
                <Item style={{ marginBottom: 0 }}>
                  <Item
                    label="Country"
                    name="workCountry"
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 8px)',
                      marginBottom: '8px',
                    }}
                  >
                    <Input placeholder="Country" />
                  </Item>
                  <Item
                    label="City"
                    name="workCity"
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 0px)',
                      margin: '0 0 0 8px',
                    }}
                  >
                    <Input placeholder="City" />
                  </Item>
                </Item>
                <Item style={{ marginBottom: 0 }}>
                  <Item
                    label="State"
                    name="workState"
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 8px)',
                    }}
                  >
                    <Input placeholder="State" />
                  </Item>
                  <Item
                    label="Zip"
                    name="workZip"
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 0px)',
                      margin: '0 0 0 8px',
                    }}
                  >
                    <Input placeholder="Zip" />
                  </Item>
                </Item>
              </Spin>
            </ScrollBar>
          </Col>
        </Row>
      </Form>
    </>
  )
}
