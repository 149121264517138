export interface RegisterUserData {
  email: string
  password: string
  userName: string
  firstName?: string
  lastName?: string
  captchaId: string
  captcha: string
}

export interface UserPreferencesData {
  settings: any
  defaultOrganization: string
}

export interface UserData {
  userOrgId: string
  organizationRoles: string[]
  id: string
  invitationStatus: string
  userName: string
  email: string
  phoneNumber: string | null
  firstname: string
  lastname: string
  gender: string | null
  avatarUrl: string | null
  profile: any
  forcePasswordChange: boolean
}

export interface UserItemData {
  userOrgId: string
  organizationRoles: string[]
  id: string
  invitationStatus: string
  userName: string
  email: string
  phoneNumber: string | null
  firstname: string
  lastname: string
  gender: string | null
  avatarUrl: string | null
  profile: any
}

export interface UserFilterState {
  status: string
  userType: string[]
  criteria: string
}

export enum UserInviteStatus {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Declined = 'Declined',
}
