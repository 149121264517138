import React, { FC, useEffect, useRef } from 'react'
import classNames from 'classnames'
import Scrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import styles from './ScrollBar.module.less'
// import './index.less';

interface ScrollBarProps {
  children: React.ReactNode
  className?: string
  height?: string | number
  style?: React.CSSProperties
}

export const ScrollBar: FC<ScrollBarProps> = ({
  children,
  className,
  ...rest
}) => {
  const comp = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let ps = comp.current ? new Scrollbar(comp.current) : null
    if (ps) ps.railXMarginWidth = 5
    return () => {
      if (ps) {
        ps.destroy()
        ps = null
      }
    }
  }, [children])

  return (
    <div
      ref={comp}
      className={classNames(className, styles.divScrollBar)}
      {...rest}
    >
      {children}
    </div>
  )
}
