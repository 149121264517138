import React, { FC } from 'react';
import { Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import styles from './Roles.module.less';

interface MoreActionMenuProps {
  userId: string;
  roleName: string;
  onClick: (evt: any, userId: string, roleName: string) => Promise<void>;
}

export const MoreActionMenu: FC<MoreActionMenuProps> = ({
  userId,
  roleName,
  onClick,
}) => {
  return (
    <Dropdown
      overlay={
        <Menu onClick={(evt) => onClick(evt, userId, roleName)}>
          <Menu.Item key="remove">Remove</Menu.Item>
        </Menu>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <MoreOutlined className={styles.moreAction} />
    </Dropdown>
  );
};
