import { from } from 'rxjs'
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { RootEpic } from '~/Store/types'
import { errorNotification, LicenseService } from '~/Services'
import { OrganizationsService } from '~/Services/OrganizationService'
import { OrganizationActions } from './slice'
import { UserActions } from '../User'

const getLicenseInfoEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(OrganizationActions.getLicenseInfo.match),
    switchMap(action => {
      const { payload } = action
      return from(LicenseService.Get.getLicenseInfoById(payload)).pipe(
        map(res => OrganizationActions.getLicenseInfoSuccess(res.data)),
        catchError(error => {
          errorNotification(error)
          return [OrganizationActions.getLicenseInfoFail()]
        }),
      )
    }),
  )

const registerUserByAdminOrgEpic: RootEpic = action$ =>
  action$.pipe(
    filter(OrganizationActions.registerUserByAdminOrgRequest.match),
    switchMap(action => {
      const { payload } = action
      return from(
        OrganizationsService.Post.addUserToOrganization(payload),
      ).pipe(
        map(res => OrganizationActions.registerUserByAdminOrgSuccess(res.data)),
        catchError(error => {
          errorNotification(error)
          return [OrganizationActions.registerUserByAdminOrgFailed()]
        }),
      )
    }),
  )

const removeUsersFromOrganizationEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(OrganizationActions.removeUsersFromOrganization.match),
    switchMap(action => {
      const { orgId, userIds } = action.payload
      return from(OrganizationsService.Delete.removeUsers(orgId, userIds)).pipe(
        map(res =>
          OrganizationActions.removeUsersFromOrganizationSuscess(res.data),
        ),
        map(() => UserActions.getMyOrganizations()),
        catchError(error => {
          errorNotification(error)
          return [OrganizationActions.removeUsersFromOrganizationFail()]
        }),
      )
    }),
  )

const filterUsersToInviteEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(OrganizationActions.filterUsersToInvite.match),
    switchMap(action => {
      const { payload } = action
      return from(OrganizationsService.Get.getUsersToInvite(payload)).pipe(
        map(res => OrganizationActions.filterUsersToInviteSuccess(res.data)),
        catchError(error => {
          errorNotification(error)
          return [OrganizationActions.filterUsersToInviteSuccess]
        }),
      )
    }),
  )

const inviteUsersToOrgEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(OrganizationActions.inviteUsersToOrg.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { orgId, userIds, andNewUser } = action.payload
      return from(
        OrganizationsService.Post.addOrganizationUser(orgId, userIds),
      ).pipe(
        map(() => OrganizationActions.inviteUsersToOrgSuccess({ andNewUser })),
        catchError(error => {
          errorNotification(error)
          return [OrganizationActions.inviteUsersToOrgFail()]
        }),
      )
    }),
  )

const getOrganizationUsersEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(OrganizationActions.getOrganizationUsers.match),
    switchMap(action => {
      const { orgId, filterData, roles, invitationStatus } = action.payload
      return from(
        OrganizationsService.Get.getOrganizationUsers(
          orgId,
          filterData,
          roles,
          invitationStatus,
        ),
      ).pipe(
        map(res => OrganizationActions.getOrganizationUsersSuccess(res.data)),
        catchError(error => {
          errorNotification(error)
          return [OrganizationActions.getOrganizationUsersFail()]
        }),
      )
    }),
  )

export const OrganizationEpics = [
  getLicenseInfoEpic,
  filterUsersToInviteEpic,
  registerUserByAdminOrgEpic,
  removeUsersFromOrganizationEpic,
  inviteUsersToOrgEpic,
  getOrganizationUsersEpic,
]
