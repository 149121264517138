import React, { FC } from 'react';
import { OrgRoleItemResponse } from '~/Services/OrganizationService';
import { useAppSelector } from '~/Store/hooks';
import { getOrganizationUsersByRole } from '~/Store/Organization/selectors';
import { OrgClaim } from '../../Routes';
import { UserItem } from './UserItem';

interface UserListProps {
  role: OrgRoleItemResponse;
}

export const UserList: FC<UserListProps> = ({ role }) => {
  const users = useAppSelector((state) => getOrganizationUsersByRole(state, role.name));
  const allowRemoveUser = role.name !== OrgClaim.Member;// && role.name !== OrgClaim.Superuser

  return (
    <>
      {users.map((item) => (
        <UserItem role={{...role, allowRemoveUser}} user={item} key={item.id} />
      ))}
    </>
  );
};
