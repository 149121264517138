import React, { useEffect } from 'react'
import { Avatar, Col, ColProps, Divider, List, Row, Typography } from 'antd'
import { UserActions, UserSelectors } from '~/Store/User'
import { useAppDispatch, useAppSelector } from '~/Store/hooks'
import { UserService } from '~/Services'
import { ScrollBar } from '~/Components/ScrollBar'
import { OrganizationItem, OrganizationItemData } from './OrganizationItem'
import { WaitOverlay } from '~/Components'
import styles from './Info.module.less'
import { WaitOverlayActions } from '~/Store/Overlays'
import { LicenseCards } from '~/Components/LicenseCards/LicenseCards'
import { Utils } from '~/Utils'
import { UserInviteStatus } from '~/Services/UserService.d'
import { RootState } from '~/Store/reducers'
import { getEditState } from '~/Store/User/selectors'
import { EditProfile } from './EditProfile'

const { Title, Text } = Typography

const waitOverlayName = 'fetchingOrgUsers'

export const ProfileInfo = () => {
  const dispatch = useAppDispatch()
  const userProfileAvatar = useAppSelector(UserSelectors.getAvatarUrl)
  const currentUser = useAppSelector(UserSelectors.getCurrentUser)
  const organization = useAppSelector(UserSelectors.getDefaultOrganization)
  const organizations = useAppSelector((state: RootState) =>
    UserSelectors.getUserOrganizationsByStatus(state, [
      UserInviteStatus.Accepted,
      UserInviteStatus.Pending,
    ]),
  )
  const license = useAppSelector(UserSelectors.getUserLicenses)
  const editState = useAppSelector(getEditState)

  const { firstname, lastname, phoneNumber, email, avatarUrl, id, profile } =
    currentUser || {}

  const {
    companyName,
    billingAddress,
    billingAddress2,
    billingCity,
    billingState,
    billingZip,
    guid,
  } = organization || {}

  const currOrgLicense = license.find(l => l.organizationId === guid)

  const getUserProfile = async () => {
    if (currentUser) return
    try {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }),
      )
      const req = await UserService.Get.fetchCurrentUser()
      const { data } = req
      dispatch(UserActions.setCurrentUser(data))
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }),
      )
    }
  }

  const fetchUserLicense = async () => {
    try {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }),
      )
      const req: any = await UserService.Get.fetchCurrentUserLicenses()
      const { data } = req
      dispatch(UserActions.setUserLicenses(data))
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }),
      )
    }
  }

  useEffect(() => {
    getUserProfile()
  }, [currentUser])
  useEffect(() => {
    fetchUserLicense()
  }, [])

  const colProps: ColProps = {
    xl: 12,
    xs: 24,
  }

  const redirectToEditProfile = () => {
    dispatch(UserActions.setEditState(true))
  }

  return (
    <WaitOverlay
      name={waitOverlayName}
      size="large"
      wrapperClassName={styles.FullOverlay}
    >
      {editState ? (
        <EditProfile />
      ) : (
        <div>
          <Row align="stretch">
            <Title style={{ flex: 1 }} level={4}>
              Personal Information
            </Title>
            <Typography.Link editable onClick={redirectToEditProfile}>
              Edit profile
            </Typography.Link>
          </Row>
          <ScrollBar style={{ paddingRight: '16px', marginRight: '-16px' }}>
            <div className={styles.UserInfo}>
              <Row style={{ marginBottom: '10px' }}>
                <Col xl={12} xs={24}>
                  <Row>
                    <Col>
                      <Avatar
                        shape="square"
                        size={140}
                        src={userProfileAvatar}
                        style={{
                          backgroundColor: avatarUrl
                            ? undefined
                            : Utils.stringToColour(id),
                        }}
                        gap={1}
                      >
                        {(firstname || email)?.charAt(0).toLocaleUpperCase()}
                      </Avatar>
                    </Col>
                    <Col>
                      <List size="small" grid={{ gutter: 8, column: 1 }}>
                        <List.Item>
                          <Title level={5}>
                            {`${firstname} ${lastname}`}
                            {companyName && <> &bull;{` ${companyName}`}</>}
                          </Title>
                        </List.Item>
                        <List.Item>{email}</List.Item>
                        <List.Item>Job Title</List.Item>
                        <List.Item>{phoneNumber}</List.Item>
                      </List>
                    </Col>
                  </Row>
                </Col>
                {currOrgLicense ? (
                  <LicenseCards license={currOrgLicense} col={colProps} />
                ) : null}
              </Row>
              <Row>
                {profile?.address && (
                  <Col xl={8} xs={24}>
                    <Row>
                      <Title level={5}>Personal Address</Title>
                    </Row>
                    <Row>
                      <List grid={{ column: 1, gutter: 8 }}>
                        <List.Item>
                          <Text>{profile?.address}</Text>
                        </List.Item>
                        <List.Item>
                          <Text>{profile?.address2}</Text>
                        </List.Item>
                        <List.Item>
                          {/* prettier-ignore */}
                          <Text>
                            {Utils.combineStrings([profile?.state, profile?.city, profile?.country],', ')}
                          </Text>
                        </List.Item>
                      </List>
                    </Row>
                  </Col>
                )}
                {profile?.workAddress && (
                  <Col xl={8} xs={24}>
                    <Row>
                      <Title level={5}>Work Address</Title>
                    </Row>
                    <Row>
                      <List grid={{ column: 1, gutter: 8 }}>
                        <List.Item>
                          <Text>{profile?.workAddress}</Text>
                        </List.Item>
                        <List.Item>
                          <Text>{profile?.workAddress2}</Text>
                        </List.Item>
                        <List.Item>
                          {/* prettier-ignore */}
                          <Text>
                            {Utils.combineStrings([profile?.workState,profile?.workCity,profile?.workCountry], ', ')}
                          </Text>
                        </List.Item>
                      </List>
                    </Row>
                  </Col>
                )}
                {organization && (
                  <Col>
                    <Row>
                      <Title level={5}>Company Address</Title>
                    </Row>
                    <Row>
                      <List grid={{ column: 1, gutter: 8 }}>
                        <List.Item>
                          <Text>{billingAddress}</Text>
                        </List.Item>
                        <List.Item>
                          <Text>{billingAddress2}</Text>
                        </List.Item>
                        <List.Item>
                          <Text>
                            {[billingCity, billingState, billingZip]
                              .filter(x => x && x.trim().length > 0)
                              .join(', ')}
                          </Text>
                        </List.Item>
                      </List>
                    </Row>
                  </Col>
                )}
              </Row>
              {organizations.length > 0 && (
                <>
                  <Divider />
                  <Row align="stretch">
                    <Title style={{ flex: 1 }} level={4}>
                      My Organizations
                    </Title>
                  </Row>
                  <div>
                    <Row
                      align="stretch"
                      gutter={[10, 10]}
                      style={{ marginRight: '0px' }}
                    >
                      {organizations.map(org => (
                        <OrganizationItem
                          key={org.guid}
                          org={org as OrganizationItemData}
                          licenseName={
                            currOrgLicense
                              ? currOrgLicense.licenseType.name
                              : ''
                          }
                        />
                      ))}
                    </Row>
                  </div>
                </>
              )}
            </div>
          </ScrollBar>
        </div>
      )}
    </WaitOverlay>
  )
}
