import {
  Avatar,
  Button,
  Col,
  message,
  notification,
  Row,
  Tag,
} from 'antd';
import React, { FC, useState } from 'react';
import { errorNotification, UserService } from '~/Services';
import { UserInviteStatus } from '~/Services/UserService.d';
import { Utils } from '~/Utils';
import { tagColor } from '../../Organizations/Users/UserItem';
import { OrganizationItemData } from '../Info/OrganizationItem';
import styles from './Invitation.module.less';

interface InvitationItemProps {
  org: OrganizationItemData,
  refreshOrganizations: () => Promise<void>,
}

export const InvitationItem: FC<InvitationItemProps> = ({ org, refreshOrganizations }) => {
  const { guid, invitationStatus } = org;
  const [confirming, setConfirming] = useState('');
  const isPending = org.invitationStatus === UserInviteStatus.Pending;
  const isDeclined = org.invitationStatus === UserInviteStatus.Declined;

  const responseInvitation = async (accept: boolean) => {
    try {
      setConfirming(accept ? 'Accept': 'Decline');
      await UserService.Put.confirmInvitation(guid, accept);
      await refreshOrganizations();
    } catch (error: any) {
      errorNotification(error);
    } finally {
      setConfirming('');
    }
  };
  return (
    <Col
      xl={12}
      xs={24}
    >
      <Row align="middle" justify="space-between" className={styles.OrgRow}
        style={{
          cursor:
            isDeclined
              ? 'not-allowed'
              : 'default',
          backgroundColor:
            isPending || isDeclined
              ? '#f5f5f5'
              : 'transparent',
        }}
      >
        <Row>
          <Col className={styles.OrganizationItemAvatar}>
            <Avatar
              shape='square'
              size={50}
              src={org.avatarUrl}
              style={{
                backgroundColor: org.avatarUrl || isDeclined
                  ? undefined
                  : Utils.stringToColour(org.guid),
              }}
            >
              {org.companyName.charAt(0).toLocaleUpperCase()}
            </Avatar>
          </Col>
          <Col>
            <Row style={{ color: isDeclined ? '#00000040' : '#666989' }}>
              <strong>{org.companyName}</strong>
            </Row>
            <Row style={{ color: isDeclined ? '#00000040' : '#666989' }}>
              {org.billingAddress}
            </Row>
          </Col>
        </Row>
        <Row
          className={styles.OrgAcpBtn}
        >
          {!invitationStatus || invitationStatus === 'Pending' ? (
            <Row>
              <Button
                type="text"
                shape="round"
                loading={confirming === 'Decline'}
                onClick={() => responseInvitation(false)}
              >
                Decline
              </Button>
              <Button
                type="primary"
                shape="round"
                loading={confirming === 'Accept'}
                onClick={() => responseInvitation(true)}
              >
                Accept
              </Button>
            </Row>
          )
            : (
              <Row>
                <Tag color={tagColor(invitationStatus)}>
                  {invitationStatus}
                </Tag>
              </Row>
            )}
        </Row>
      </Row>
    </Col>
  );
};
