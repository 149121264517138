import React, { useEffect, useState } from 'react'
import {
  Typography,
  Pagination,
  Row,
  notification,
  Modal,
  Tooltip,
  Spin,
} from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import styles from './Users.module.less'
import { UserItem } from './UserItem'
import { getLeftSiderCollapsed } from '~/Store/Layout/selectors'
import { UserFilter } from './UserFilter'
import { OrganizationsService } from '~/Services/OrganizationService'
import { getDefaultOrganization } from '~/Store/User/selectors'
import { UserData, UserItemData } from '~/Services/UserService.d'
import { getPagination, GlobalActions } from '~/Store/Global'
import { useAppDispatch, useAppSelector } from '~/Store/hooks'
import {
  getModalVisibility,
  getOrganizationListUsersRefetch,
  getOrgUserCurrentPage,
  getUserFilterState,
} from '~/Store/Organization/selectors'
import { OrganizationActions, OrgModelName } from '~/Store/Organization'
import useModal from '~/Hooks/useModal'
import { RemoveUser } from './RemoveUser'
import { errorNotification, UserService } from '~/Services'
import { WaitOverlay } from '~/Components'
import { WaitOverlayActions } from '~/Store/Overlays'
import { NoData } from '~/Components/NoData'
import { ScrollBar } from '~/Components/ScrollBar'
import useHasPermissions from '~/Hooks/useHasPermissions'
import { OrgClaim, RootClaim } from '../../Routes'
import { UserAddOutlined } from '@ant-design/icons'
import { CreateUser, UserInformation } from './CreateUser'
import { UserProfileObject } from '~/Services/organization.types'
import { InviteUser } from './InviteUser'
export const Users = () => {
  const waitOverlayName = OrgModelName.fetchingOrgUsers
  const dispatch = useAppDispatch()
  const leftMenuCollapsed = useAppSelector(getLeftSiderCollapsed)
  const defaultOrg = useAppSelector(getDefaultOrganization)
  const hasAdmin = useHasPermissions([RootClaim.System, OrgClaim.Admin])
  const pagination = useAppSelector(getPagination)
  const { pageSize } = pagination
  const currentPage = useAppSelector(getOrgUserCurrentPage)
  const refetch = useAppSelector(getOrganizationListUsersRefetch)
  const fetchingUsers = useAppSelector(
    getModalVisibility(OrgModelName.fetchingOrgUsers),
  )
  const [users, setUsers] = useState<UserItemData[]>([])
  const [totalItem, setTotalItem] = useState(1)
  const filterState = useAppSelector(getUserFilterState)

  const [isFetching, setFetching] = useState(false)
  const [user, setUser] = useState<UserData>()
  const [licenses, setLicenses] = useState<any[]>([])
  const [removing, setRemoving] = useState(false)
  const isModalVisible = useAppSelector(
    getModalVisibility(OrgModelName.newUserModal),
  )

  const removeUserModal = useModal({
    content: users,
    onOpen: async (item?: UserItemData) => {
      if (item) {
        // fetch user
        try {
          setFetching(true)
          const ps: Promise<any>[] = []
          ps.push(UserService.Get.fetchUserByEmail(item.email))
          ps.push(UserService.Get.fetchUserLicenses(item.id))
          const res = await Promise.all(ps)
          const { data: userData } = res[0]
          if (userData) setUser(userData)
          const { data: userLicenses } = res[1]
          if (userLicenses && defaultOrg) {
            setLicenses(
              Array.from(userLicenses).filter(
                (x: any) => x.organizationId === defaultOrg.guid,
              ),
            )
          }
        } catch (error) {
          // todo
          console.log(error)
        } finally {
          setFetching(false)
        }
      }
    },
  })

  const fetchOrganizationUsers = async () => {
    if (!defaultOrg) return
    try {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }),
      )
      let statusFilter = ''
      // if (filterState.status) {
      //   statusFilter = `invitationStatus eq '${filterState.status}'`;
      // }
      let queryString = ''
      if (filterState.criteria) {
        queryString += `(contains(userName, '${filterState.criteria}')`
        queryString += ` or contains(email, '${filterState.criteria}')`
        queryString += ` or contains(phoneNumber, '${filterState.criteria}')`
        queryString += ` or contains(firstname, '${filterState.criteria}')`
        queryString += ` or contains(lastname, '${filterState.criteria}'))`
      }
      const res: any = await OrganizationsService.Get.getOrganizationUsers(
        defaultOrg.guid,
        {
          filter:
            [statusFilter, queryString].filter(x => x).join(' and ') ||
            undefined,
          expand: 'Licenses',
          page: currentPage,
          pageSize,
        },
        filterState.userType.length > 0 ? filterState.userType : undefined,
        filterState.status ? [filterState.status] : undefined,
      )
      const { data } = res
      const { page, queryCount, results } = data
      setUsers(results)
      dispatch(OrganizationActions.setUsersCurrentPage(page))
      setTotalItem(queryCount)
    } catch (error: any) {
      errorNotification(error)
    } finally {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }),
      )
    }
  }

  const handleRemoveUser = async (values: Partial<UserData>) => {
    try {
      setRemoving(true)
      await OrganizationsService.Delete.removeUsers(defaultOrg!.guid, [
        values.id!,
      ])
      // refetch users and close modal
      await fetchOrganizationUsers()
      notification.open({
        message: 'Remove user',
        description: 'Remove successfully.',
        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      })
      removeUserModal.close()
    } catch (error) {
      // todo
      console.log(error)
    } finally {
      setRemoving(false)
    }
  }

  useEffect(() => {
    fetchOrganizationUsers()
  }, [defaultOrg, currentPage, pageSize, filterState, refetch])

  const changePage = (_page: number, _pageSize?: number | undefined) => {
    dispatch(OrganizationActions.setUsersCurrentPage(_page))
    dispatch(
      GlobalActions.setPagination({ ...pagination, pageSize: _pageSize }),
    )
  }

  const handleOk = (value: UserInformation) => {
    // namdd will do
    const userProfile: UserProfileObject = {
      userName: value.email,
      email: value.email,
      firstname: value.firstName,
      lastname: value.lastName,
      password: value.password,
      forcePasswordChange: false,
      gender: '',
    }
    dispatch(OrganizationActions.registerUserByAdminOrgRequest(userProfile))
  }

  const handleCancel = () => {
    dispatch(
      OrganizationActions.setModalState({
        name: OrgModelName.newUserModal,
        value: false,
      }),
    )
  }

  const showInviteUserModal = () => {
    dispatch(
      OrganizationActions.setModalState({
        name: OrgModelName.inviteUserVisibility,
        value: true,
      }),
    )
  }

  return (
    <WaitOverlay
      name={waitOverlayName}
      size="large"
      wrapperClassName={styles.FullOverlay}
    >
      <InviteUser />
      {/* <NewUser /> */}
      <Modal
        title="Create user"
        visible={isModalVisible}
        okButtonProps={{ disabled: true, hidden: true }}
        cancelButtonProps={{ disabled: true, hidden: true }}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateUser onSubmit={handleOk} />
      </Modal>
      {removeUserModal.visible && (
        <RemoveUser
          user={user || {}}
          licenses={licenses}
          loadingData={isFetching}
          onOk={handleRemoveUser}
          onCancel={removeUserModal.close}
          confirmLoading={removing}
          footer={null}
        />
      )}
      <div className={styles.OrganizationUsers}>
        <Row align="stretch">
          <Typography.Title style={{ flex: 1 }} level={4}>
            Organization Users
          </Typography.Title>
          <Tooltip title="Invite User">
            <UserAddOutlined
              className={styles.AddUser}
              onClick={showInviteUserModal}
              style={{ color: 'blue' }}
            />
          </Tooltip>
          <UserFilter />
        </Row>
        <Spin spinning={fetchingUsers} size="large">
          <div className={styles.UserList}>
            <ScrollBar style={{ paddingRight: '16px', marginRight: '-16px' }}>
              {users.length > 0 ? (
                users.map((item: UserItemData) => (
                  <UserItem
                    key={item.id}
                    user={item}
                    onClick={removeUserModal.toggle}
                    hasAdmin={hasAdmin}
                  />
                ))
              ) : (
                <NoData content="No data" />
              )}
            </ScrollBar>
          </div>
        </Spin>
        <div
          className={styles.Pagination}
          style={{
            width: `calc(100% - ${leftMenuCollapsed ? '146' : '266'}px)`,
          }}
        >
          <Pagination
            current={currentPage}
            total={totalItem}
            pageSize={pageSize}
            showSizeChanger
            responsive
            onChange={changePage}
          />
        </div>
      </div>
    </WaitOverlay>
  )
}
