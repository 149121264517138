import { RootState } from '../reducers'

export const getIsLoading = (state: RootState) => state.license.isLoading
export const getIsConfirmLoading = (state: RootState) =>
  state.license.isConfirmLoading
export const getLicenseTypes = (state: RootState) => state.license.licenseTypes
export const getLicenseTypeSelected = (state: RootState) =>
  state.license.licenseTypeSelected
export const getProductList = (state: RootState) => state.license.productList
export const getPageMeta = (state: RootState) => state.license.pageMeta
export const getModalVisibility = (name: string) => (state: RootState) =>
  state.license.modalVisibility[name]?.visible || false
