import React from 'react';
import { Avatar, AvatarProps } from 'antd';
import { UserItemData } from '~/Services/UserService.d';
import { Utils } from '~/Utils';

interface UserAvatarProps extends AvatarProps {
  user: UserItemData;
}

export const UserAvatar = React.memo(({ user, ...rest }: UserAvatarProps) => {
  return (
    <Avatar
      src={Utils.avatarUrl(user.avatarUrl)}
      style={{ backgroundColor: Utils.stringToColour(user.id) }}
      {...rest}>
      {Utils.getFirstInitLastName(user.email, user.firstname, user.lastname)}
    </Avatar>
  );
});
