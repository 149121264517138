import React, { useState } from 'react'
import { Button, Col, Form, Input, Popover, Radio, Row } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { FilterSvg } from '~/Components'
import { useAppDispatch, useAppSelector } from '~/Store'
import {
  getDeviceSearchState,
  getFilterDevices,
} from '~/Store/Devices/selectors'
import { ActiveState } from '~/Types/Enums'
import { DevicesActions } from '~/Store/Devices'

interface FilterState {
  status: string
}

export const DevicesFilter = () => {
  const dispatch = useAppDispatch()
  const filter = useAppSelector(getFilterDevices)
  const searchState = useAppSelector(getDeviceSearchState)
  const [showAdvancedFilter, setAdvancedFilterVisibility] = useState(false)
  const [timer, setTimer] = useState<any>(null)
  const [filterString, setFilterString] = useState('')
  const [form] = Form.useForm()

  const handleClickStatus = (evt: any) => {
    const values = form.getFieldsValue()
    if (evt.target.value === form.getFieldValue('status')) {
      form.setFieldsValue({ ...values, status: '' })
    }
  }

  const fetchingDevices = (values: FilterState, findQuery: string) => {
    let queryString: string | undefined
    if (values.status) {
      queryString = `isActive eq ${
        values.status === ActiveState.Active ? true : false
      }`
    }
    let nestedFilter: string | undefined
    if (findQuery) {
      nestedFilter = findQuery
      // const queryStr = ``
      // if (queryString) {
      //   queryString += ` and ${queryStr}`
      // } else {
      //   queryString = queryStr
      // }
    }
    dispatch(
      DevicesActions.getListDevices({
        filter: queryString,
        userName: nestedFilter,
        deviceName: nestedFilter,
        devicePlatform: nestedFilter,
        pageSize: filter.pageSize,
        paging: filter.paging,
        page: 1,
      }),
    )
    dispatch(
      DevicesActions.setSearchState({
        status: values.status,
        filterString: findQuery,
      }),
    )
    setAdvancedFilterVisibility(false)
  }

  const queryChange = (filter: string) => {
    clearTimeout(timer)
    const timeoutId = setTimeout(() => {
      setFilterString(filter)
      // prettier-ignore
      fetchingDevices(form.__INTERNAL__.name ? form.getFieldsValue() : {}, filter)
    }, 500)
    setTimer(timeoutId)
  }

  const advancedFilterToggle = () => {
    setAdvancedFilterVisibility(!showAdvancedFilter)
  }

  return (
    <Row gutter={10}>
      <Col>
        <Form
          initialValues={{ findQuery: searchState.filterString }}
          onFieldsChange={(_, allFields) => queryChange(allFields[0].value)}
          autoComplete="off"
        >
          <Form.Item name="findQuery">
            <Input prefix={<SearchOutlined />} placeholder="Search" />
          </Form.Item>
        </Form>
      </Col>
      <Col>
        <Popover
          placement="bottomRight"
          content={
            <Form
              form={form}
              onFinish={values => fetchingDevices(values, filterString)}
              layout="horizontal"
              initialValues={{ status: searchState.status }}
              name="AdvancedFilter"
            >
              <Form.Item label="Status" name="status">
                <Radio.Group optionType="button" buttonStyle="solid">
                  <Radio.Button
                    onClick={handleClickStatus}
                    value={ActiveState.Active}
                  >
                    {ActiveState.Active}
                  </Radio.Button>
                  <Radio.Button
                    onClick={handleClickStatus}
                    value={ActiveState.Deactive}
                  >
                    {ActiveState.Deactive}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Button type="primary" htmlType="submit">
                    Filter
                  </Button>
                </Col>
              </Row>
            </Form>
          }
          trigger="click"
          visible={showAdvancedFilter}
          onVisibleChange={advancedFilterToggle}
        >
          <div
            style={{
              cursor: 'pointer',
              height: '32px',
              width: '25px',
              paddingTop: '7px',
            }}
          >
            <FilterSvg height={16} width={18} />
          </div>
        </Popover>
      </Col>
    </Row>
  )
}
