import { Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { useEffect } from 'react'
import { WaitOverlay } from '~/Components'
import { ScrollBar } from '~/Components/ScrollBar'
import { errorNotification, UserService } from '~/Services'
import { useAppDispatch, useAppSelector } from '~/Store/hooks'
import { WaitOverlayActions } from '~/Store/Overlays'
import { UserActions, UserSelectors } from '~/Store/User'
import { OrganizationItemData } from '../Info/OrganizationItem'
import { InvitationItem } from './InvitationItem'

const waitOverlayName = 'fetchPendingOrgs'

export const Invitation = () => {
  const dispatch = useAppDispatch()
  const organizations = useAppSelector(UserSelectors.getUserOrganizations)

  const fetchPendingOrganizations = async () => {
    try {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }),
      )
      const res = await UserService.Get.fetchCurrentUserOrganizations()
      dispatch(UserActions.setUserOrganizations(res.data))
    } catch (err) {
      errorNotification(err)
    } finally {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }),
      )
    }
  }
  useEffect(() => {
    fetchPendingOrganizations()
  }, [])

  return (
    <WaitOverlay name={waitOverlayName}>
      {organizations.length > 0 ? (
        <>
          <Title style={{ flex: 1 }} level={4}>
            My Organizations
          </Title>
          <div style={{ height: 'calc(100vh - 230px)' }}>
            <ScrollBar style={{ paddingRight: '16px', marginRight: '-16px' }}>
              <Row
                align="stretch"
                gutter={[10, 10]}
                style={{ marginRight: '0px' }}
              >
                {organizations.map((org: any) => (
                  <InvitationItem
                    key={org.guid}
                    org={org as OrganizationItemData}
                    refreshOrganizations={fetchPendingOrganizations}
                  />
                ))}
              </Row>
            </ScrollBar>
          </div>
        </>
      ) : (
        <div>{"You don't have any invitation yet"}</div>
      )}
    </WaitOverlay>
  )
}
