import { forkJoin, from } from 'rxjs'
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { errorNotification, LicenseService, ProductService } from '~/Services'
import { RootEpic } from '../types'
import { LicenseMgmtActions } from './slice'

const licenseTypeAdminLoadDataEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LicenseMgmtActions.licenseTypeAdminLoadData.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return forkJoin([
        LicenseService.Get.getLicenseTypes({
          params: {
            paging: true,
            page: 1,
            pageSize: state.license.pageMeta?.pageSize || 10,
          },
        }),
        ProductService.Get.getProducts({ params: { paging: false } }),
      ]).pipe(
        map(([typesRes, productsRes]) =>
          LicenseMgmtActions.licenseTypeAdminLoadDataSuccess({
            licenseTypesData: typesRes.data,
            productsData: productsRes.data,
          }),
        ),
        catchError(error => {
          errorNotification(error)
          return [LicenseMgmtActions.licenseTypeAdminLoadDataFail()]
        }),
      )
    }),
  )
}

const getLicenseTypesEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LicenseMgmtActions.getLicenseTypes.match),
    switchMap(action => {
      const { payload } = action
      return from(LicenseService.Get.getLicenseTypes({ params: payload })).pipe(
        map(res => LicenseMgmtActions.getLicenseTypesSuccess(res.data)),
        catchError(error => {
          errorNotification(error)
          return [LicenseMgmtActions.getLicenseTypesFail()]
        }),
      )
    }),
  )
}

const createLicenseTypeEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LicenseMgmtActions.createLicenseType.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { payload } = action
      return from(LicenseService.Post.createLicenseType(payload)).pipe(
        concatMap(res => [
          LicenseMgmtActions.createLicenseTypeSuccess(res.data),
          LicenseMgmtActions.getLicenseTypes({
            paging: true,
            page: 1,
            pageSize: state.license.pageMeta?.pageSize || 10,
          }),
        ]),
        catchError(error => {
          errorNotification(error)
          return [LicenseMgmtActions.createLicenseTypeFail()]
        }),
      )
    }),
  )
}

const updateLicenseTypeEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LicenseMgmtActions.updateLicenseType.match),
    switchMap(action => {
      const { payload } = action
      return from(
        LicenseService.Put.updateLicenseType(payload['guid'], payload),
      ).pipe(
        map(res => LicenseMgmtActions.updateLicenseTypeSuccess(res.data)),
        catchError(error => {
          errorNotification(error)
          return [LicenseMgmtActions.updateLicenseTypeFail()]
        }),
      )
    }),
  )
}

const deleteLicenseTypeEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LicenseMgmtActions.deleteLicenseType.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { payload: guid } = action
      return from(LicenseService.Delete.deleteLicenseType(guid)).pipe(
        concatMap(() => [
          LicenseMgmtActions.deleteLicenseTypeSuccess(),
          LicenseMgmtActions.getLicenseTypes({
            paging: true,
            page: 1,
            pageSize: state.license.pageMeta?.pageSize || 10,
          }),
        ]),
        catchError(error => {
          errorNotification(error)
          return [LicenseMgmtActions.deleteLicenseTypeFail()]
        }),
      )
    }),
  )
}

export const LicenseMgmtEpics = [
  getLicenseTypesEpic,
  createLicenseTypeEpic,
  updateLicenseTypeEpic,
  licenseTypeAdminLoadDataEpic,
  deleteLicenseTypeEpic,
]
