import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrgRoleItemResponse } from '~/Services/OrganizationService'
import { UserFilterState, UserItemData } from '~/Services/UserService.d'
import { UserProfileObject } from '~/Services/organization.types'
import { OrgModelName } from './enums'

export interface OrganizationReducerStore {
  info: OrganizationInfo
  users: {
    data: UserItemData[]
    currentPage: number
    filter: UserFilterState
    total: number
    refetch: boolean
  }
  licenses: LicenseData[]
  isEditMode: boolean
  roles: OrgRoleItemResponse[]
  roleSelected: Partial<OrgRoleItemResponse>
  orgUsers: UserItemData[]
  modalState: {
    [key: string]: boolean
  }
  roleActivePanel?: string[] | string
  isLoading: boolean
  licenseSelected?: LicenseData
  licenseInfo?: LicenseTypeData
  usersToInvite: UserItemData[]
}

export interface LicenseData {
  activeDate: string
  expiredate: string
  guid: string
  licenseType: LicenseTypeData
  licenseTypeId: string
  organizationId: string
  number: number
  state?: string
  userGuid?: string[]
  users: UserItemData[]
}
export interface LicenseTypeData {
  guid: string
  name: string
  offlineRange: number
  products?: LicenseProduct[]
  productID: string
  type: string
}

interface LicenseProduct {
  guid: string
  name: string
  clientId: string
  claims: LicenseProductClaim[]
}

interface LicenseProductClaim {
  guid: string
  claimType: string
  claimValue: string
}

export interface OrganizationInfo {
  companyName: string
  billingAddress: string
  billingAddress2: string
  billingCity: string
  billingState: string
  billingZip: string
  crmId: string
  duns: string
  naics: string
  guid: string
}

const initInfo: OrganizationInfo = {
  companyName: '',
  billingAddress: '',
  billingAddress2: '',
  billingCity: '',
  billingState: '',
  billingZip: '',
  crmId: '',
  duns: '',
  naics: '',
  guid: '',
}

const initialState: OrganizationReducerStore = {
  info: initInfo,
  users: {
    data: [],
    currentPage: 1,
    filter: { status: '', userType: [], criteria: '' },
    total: 0,
    refetch: false,
  },
  licenses: [],
  isEditMode: false,
  roles: [],
  roleSelected: {},
  orgUsers: [],
  modalState: {},
  isLoading: false,
  usersToInvite: [],
}

export const OrganizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.info.companyName = action.payload
    },
    setBillingAddress: (state, action: PayloadAction<string>) => {
      state.info.billingAddress = action.payload
    },
    setBillingAddress2: (state, action: PayloadAction<string>) => {
      state.info.billingAddress2 = action.payload
    },
    setBillingCity: (state, action: PayloadAction<string>) => {
      state.info.billingCity = action.payload
    },
    setBillingState: (state, action: PayloadAction<string>) => {
      state.info.billingState = action.payload
    },
    setBillingZip: (state, action: PayloadAction<string>) => {
      state.info.billingZip = action.payload
    },
    setDuns: (state, action: PayloadAction<string>) => {
      state.info.duns = action.payload
    },
    setNaics: (state, action: PayloadAction<string>) => {
      state.info.naics = action.payload
    },
    setOrganization: (state, action: PayloadAction<OrganizationInfo>) => {
      state.info = action.payload
    },
    setUsersCurrentPage: (state, action) => {
      state.users.currentPage = action.payload
    },
    setUserFilterState: (state, action) => {
      state.users.filter = action.payload
      state.users.currentPage = 1
    },
    setOrgLicense: (state, action) => {
      state.licenses = action.payload
    },
    toggleIsEditMode: state => {
      state.isEditMode = !state.isEditMode
    },
    setIsEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload
    },
    setOrganizationRoles: (
      state,
      action: PayloadAction<OrgRoleItemResponse[]>,
    ) => {
      state.roles = action.payload
    },
    setOrganizationUsers: (state, action: PayloadAction<UserItemData[]>) => {
      state.orgUsers = action.payload
    },
    setOrganizationRoleSelected: (
      state,
      action: PayloadAction<Partial<OrgRoleItemResponse>>,
    ) => {
      state.roleSelected = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setModalState: (
      state,
      action: PayloadAction<{ name: string; value: boolean }>,
    ) => {
      const { name, value = false } = action.payload
      state.modalState[name] = value
    },
    setRoleActivePanel: (state, action) => {
      state.roleActivePanel = action.payload
    },
    setLicenseSelected: (state, action) => {
      state.licenseSelected = action.payload
    },
    getLicenseInfo: (state, action: PayloadAction<string>) => {
      state.isLoading = true
    },
    getLicenseInfoSuccess: (state, action) => {
      const { payload } = action
      state.licenseInfo = payload
      state.isLoading = false
    },
    getLicenseInfoFail: state => {
      state.isLoading = false
    },
    filterUsersToInvite: (state, action) => {
      state.isLoading = true
    },
    filterUsersToInviteSuccess: (state, action) => {
      const { payload } = action
      state.usersToInvite = payload
      state.isLoading = false
    },
    filterUsersToInviteFail: state => {
      state.isLoading = false
    },
    removeLicenseSelected: state => {
      state.licenseSelected = undefined
      state.licenseInfo = undefined
    },
    removeUsersFromOrganization: (state, action) => {
      state.isLoading = true
    },
    removeUsersFromOrganizationSuscess: (state, action) => {
      state.isLoading = false
    },
    removeUsersFromOrganizationFail: state => {
      state.isLoading = false
    },
    inviteUsersToOrg: (state, action) => {
      state.isLoading = true
    },
    inviteUsersToOrgSuccess: (state, action) => {
      const { andNewUser } = action.payload
      state.modalState[OrgModelName.inviteUserVisibility] = false
      state.modalState[OrgModelName.confirmInviteModal] = false
      state.modalState[OrgModelName.newUserModal] = andNewUser
      state.isLoading = false
      state.users.refetch = !state.users.refetch
    },
    inviteUsersToOrgFail: state => {
      state.isLoading = false
    },
    getOrganizationUsers: (state, action) => {
      state.modalState[OrgModelName.fetchingOrgUsers] = true
    },
    getOrganizationUsersSuccess: (state, action) => {
      const { page, queryCount, results } = action.payload
      state.users.data = results
      state.users.total = queryCount
      state.users.currentPage = page
      state.modalState[OrgModelName.fetchingOrgUsers] = false
    },
    getOrganizationUsersFail: state => {
      state.modalState[OrgModelName.fetchingOrgUsers] = false
    },
    showNewUserModal: state => {
      state.modalState[OrgModelName.confirmInviteModal] = false
      state.modalState[OrgModelName.inviteUserVisibility] = false
      state.modalState[OrgModelName.newUserModal] = true
    },
    // Get members request
    registerUserByAdminOrgRequest: (
      state,
      action: PayloadAction<UserProfileObject>,
    ) => {
      state.isLoading = true
    },
    registerUserByAdminOrgSuccess: (state, action) => {
      state.modalState[OrgModelName.newUserModal] = false
      state.isLoading = false
      state.users.refetch = !state.users.refetch
    },
    registerUserByAdminOrgFailed(state) {
      state.isLoading = false
    },
  },
})

export const OrganizationActions = OrganizationSlice.actions
export const OrganizationReducer = OrganizationSlice.reducer
