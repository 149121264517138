import { from } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { RootEpic } from '~/Store/types'
import { errorNotification } from '~/Services'
import { DevicesService } from '~/Services/Devices'
import { DevicesActions } from './slice'

const deactivateDevicesEpic: RootEpic = action$ =>
  action$.pipe(
    filter(DevicesActions.deactivateDevices.match),
    switchMap(action => {
      const { payload } = action
      return from(DevicesService.deactivateDevices(payload)).pipe(
        map(res => DevicesActions.deactivateDevicesSuccess(res.data)),
        catchError(error => {
          errorNotification(error)
          return [DevicesActions.deactivateDevicesFail()]
        }),
      )
    }),
  )

const getListDevicesEpic: RootEpic = action$ =>
  action$.pipe(
    filter(DevicesActions.getListDevices.match),
    switchMap(action => {
      const { payload } = action
      return from(DevicesService.getDevices(payload)).pipe(
        map(res => DevicesActions.getListDevicesSuccess(res.data)),
        catchError(error => {
          errorNotification(error)
          return [DevicesActions.getListDevicesFail()]
        }),
      )
    }),
  )

export const DevicesEpics = [deactivateDevicesEpic, getListDevicesEpic]
