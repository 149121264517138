import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import rootEpic from "./epics";
import rootReducer, { RootState } from "./reducers";
const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
      },
    }).concat(epicMiddleware);

    return middlewares;
  },
});

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;
export { store };