import React, { FC } from 'react';
import { Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from './Roles.module.less';
import { AssignToUser } from './AssignToUser';
import { OrgRoleItemResponse } from '~/Services/OrganizationService';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { getRoleModalVisibility } from '~/Store/Organization/selectors';
import { assignRoleModalName } from './constant';
import { OrganizationActions } from '~/Store/Organization';

interface AssignRoleExtraProps {
  role: OrgRoleItemResponse;
}

export const AssignRoleExtra: FC<AssignRoleExtraProps> = ({ role }) => {
  const dispatch = useAppDispatch();
  const modalName = `${assignRoleModalName}_${role.name}`;
  const visibility = useAppSelector(getRoleModalVisibility(modalName));

  const toggleAssignToUsers = () => dispatch(OrganizationActions.setModalState({name: modalName, value: !visibility}));

  return (
    <div
      className={styles.extraAssignIcon}
      onClick={(event) => {
        dispatch(OrganizationActions.setRoleActivePanel(role.id));
        event.stopPropagation();
      }}
    >
      <Popover
        placement="leftTop"
        title="Assign to users"
        content={
          <AssignToUser role={role}/>
        }
        trigger="click"
        visible={visibility}
        onVisibleChange={toggleAssignToUsers}
      >
        <PlusOutlined />
      </Popover>
    </div>
  );
};
