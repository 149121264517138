import React, { useEffect } from 'react'
import { Modal, List, Spin, Collapse, Row } from 'antd'
import { OrganizationActions, OrgModelName } from '~/Store/Organization'
import { useAppDispatch, useAppSelector } from '~/Store'
import {
  getLicenseInfo,
  getLicenseSelected,
  getModalVisibility,
  getLoading,
} from '~/Store/Organization/selectors'
import { LicenseAvatar } from '~/Components/LicenseAvatar'
import { Utils } from '~/Utils'
import { ScrollBar } from '~/Components/ScrollBar'

const { Panel } = Collapse

export const LicenseInfo = () => {
  const dispatch = useAppDispatch()
  const licenseSelected = useAppSelector(getLicenseSelected)
  const licenseInfo = useAppSelector(getLicenseInfo)
  const loading = useAppSelector(getLoading)
  const visibility = useAppSelector(
    getModalVisibility(OrgModelName.licenseInfoVisibility),
  )

  useEffect(() => {
    if (licenseSelected) {
      dispatch(
        OrganizationActions.getLicenseInfo(licenseSelected.licenseTypeId),
      )
    }
  }, [licenseSelected])

  const closeSelf = () => {
    dispatch(
      OrganizationActions.setModalState({
        name: OrgModelName.licenseInfoVisibility,
        value: false,
      }),
    )
    dispatch(OrganizationActions.removeLicenseSelected())
  }

  return (
    <Modal
      visible={visibility}
      title="License info"
      onCancel={closeSelf}
      footer={null}
    >
      <Spin spinning={loading}>
        {licenseSelected && (
          <>
            <List.Item.Meta
              avatar={
                <LicenseAvatar
                  type={licenseSelected.licenseType.type}
                  height={50}
                  width={50}
                />
              }
              title={
                <>
                  <span style={{ fontWeight: 'bolder' }}>
                    {licenseSelected.licenseType.name}
                  </span>
                </>
              }
              description={`${
                licenseSelected.users?.filter(
                  i => i.invitationStatus === 'Accepted',
                ).length
              } out of ${licenseSelected.number} used`}
            />
            {licenseInfo && (
              <div style={{ marginTop: '10px' }}>
                <Row align="stretch">
                  <div
                    style={{ flex: 1 }}
                  >{`Activation Date: ${Utils.toLocaleDateString(
                    licenseSelected.activeDate,
                  )}`}</div>
                  <div>{`Expiration Date: ${Utils.toLocaleDateString(
                    licenseSelected.expiredate,
                  )}`}</div>
                </Row>
                <div style={{ marginTop: '10px' }}>
                  <ScrollBar
                    style={{
                      maxHeight: 'calc(100vh - 300px)',
                      paddingRight: '16px',
                      marginRight: '-16px',
                      paddingBottom: '16px',
                      marginBottom: '-16px',
                    }}
                  >
                    {licenseInfo.products && (
                      <Collapse defaultActiveKey={licenseInfo.products[0].guid}>
                        {licenseInfo.products.map(p => (
                          <Panel header={p.name} key={p.guid}>
                            {p.claims.map(c => (
                              <div key={c.guid}>{`${c.claimValue}`}</div>
                            ))}
                          </Panel>
                        ))}
                      </Collapse>
                    )}
                  </ScrollBar>
                </div>
              </div>
            )}
          </>
        )}
      </Spin>
    </Modal>
  )
}
