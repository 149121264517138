import React, { ReactNode } from 'react'
import { RouteProps } from 'react-router-dom'
import User from 'remixicon-react/UserLineIcon'
import Building from 'remixicon-react/Building4LineIcon'
import Settings2FillIcon from 'remixicon-react/Settings2FillIcon'
import { Organizations } from './Organizations'
import { Profile } from '~/Views/Main/Profile'

import { Info } from '~/Views/Main/Organizations/Info'
import { Users } from '~/Views/Main/Organizations/Users'
import { Billing } from '~/Views/Main/Organizations/Billing'
import { Licenses } from '~/Views/Main/Organizations/Licenses'
import { Roles } from '~/Views/Main/Organizations/Roles'
import { Devices } from './Admin/Devices/Devices'
import { Admin } from './Admin'
import { AdminLicenses } from './Admin/Licenses'
import { AdminProducts } from './Admin/Products'
import { AdminOrganizations } from './Admin/Organizations'

export const enum RootClaim {
  System = 'System',
  Admin = 'Admin',
  User = 'User',
}

export const enum OrgClaim {
  Member = 'Member',
  Admin = 'Admin',
  Superuser = 'SuperUser',
}

interface RouteObj {
  label: string
  icon?: ReactNode
  rootPermission: RootClaim[]
  orgPermission: OrgClaim[]
  routeProps: RouteProps
  documentTitle?: string
}

export const Routes: RouteObj[] = [
  {
    label: 'Organizations',
    rootPermission: [RootClaim.System, RootClaim.Admin],
    icon: <Building />,
    orgPermission: [OrgClaim.Member, OrgClaim.Superuser, OrgClaim.Admin],
    routeProps: {
      path: '/Organizations',
      children: <Organizations />,
    },
  },
  {
    label: 'My Profile',
    rootPermission: [RootClaim.System, RootClaim.Admin, RootClaim.User],
    orgPermission: [],
    icon: <User />,
    routeProps: {
      path: '/Profile',
      children: <Profile />,
    },
  },
  {
    label: 'System Admin',
    rootPermission: [RootClaim.System],
    orgPermission: [],
    icon: <Settings2FillIcon />,
    routeProps: {
      path: '/Admin',
      children: <Admin />,
    },
  },
]

export const OrgRoutes: RouteObj[] = [
  {
    label: 'Info',
    rootPermission: [RootClaim.System, RootClaim.Admin],
    orgPermission: [OrgClaim.Member, OrgClaim.Superuser, OrgClaim.Admin],
    routeProps: {
      path: '',
      children: <Info />,
    },
  },
  {
    label: 'Users',
    rootPermission: [RootClaim.System, RootClaim.Admin],
    orgPermission: [OrgClaim.Member, OrgClaim.Superuser, OrgClaim.Admin],
    routeProps: {
      path: '/Users',
      children: <Users />,
    },
  },
  // {
  //   label: 'Billing',
  //   rootPermission: [RootClaim.System, RootClaim.Admin],
  //   orgPermission: [OrgClaim.Admin],
  //   routeProps: {
  //     path: '/billing',
  //     children: <Billing />,
  //   },
  // },
  {
    label: 'Licenses',
    rootPermission: [RootClaim.System, RootClaim.Admin],
    orgPermission: [OrgClaim.Superuser, OrgClaim.Admin],
    routeProps: {
      path: '/Licenses',
      children: <Licenses />,
    },
  },
  {
    label: 'Roles',
    rootPermission: [RootClaim.System, RootClaim.Admin],
    orgPermission: [OrgClaim.Superuser, OrgClaim.Admin],
    routeProps: {
      path: '/Roles',
      children: <Roles />,
    },
  },
]

export const SystemRoutes: RouteObj[] = [
  {
    label: 'Organizations',
    rootPermission: [RootClaim.System],
    orgPermission: [],
    routeProps: {
      path: '',
      children: <AdminOrganizations />,
    },
  },
  {
    label: 'License Types',
    rootPermission: [RootClaim.System],
    orgPermission: [],
    routeProps: {
      path: '/LicenseTypes',
      children: <AdminLicenses />,
    },
  },
  {
    label: 'Products',
    rootPermission: [RootClaim.System],
    orgPermission: [],
    routeProps: {
      path: '/Products',
      children: <AdminProducts />,
    },
  },
  {
    label: 'Devices',
    rootPermission: [RootClaim.System],
    orgPermission: [],
    routeProps: {
      path: '/Devices',
      children: <Devices />,
    },
  },
]
