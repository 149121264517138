import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { Modal } from '~/Components/Modal';
import { OrganizationsService, OrgRoleItemResponse } from '~/Services/OrganizationService';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { getOrganizationRoleSelected } from '~/Store/Organization/selectors';
import { getDefaultOrganization } from '~/Store/User/selectors';
import { errorNotification } from '~/Services';
import { OrganizationActions } from '~/Store/Organization';
import { roleModalName } from './constant';

export const RoleModal = () => {
  const dispatch = useAppDispatch();
  const defaultOrg = useAppSelector(getDefaultOrganization);
  const role = useAppSelector(getOrganizationRoleSelected);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const storeOrganizationRole = async (values: Partial<OrgRoleItemResponse>) => {
    try {
      setConfirmLoading(true);
      if (role.id && role.name && values.name) { // update role
        await OrganizationsService.Put.updateOrganizationRole(defaultOrg?.guid, { roleName: role.name, newRoleName: values.name });
      } 
      else { // create a new role
        await OrganizationsService.Post.createOrganizationRole(defaultOrg?.guid, [values.name!]);
      }
      // refetch organization roles
      const res = await OrganizationsService.Get.getOrganizationRoles(defaultOrg?.guid);
      if (res.data) {
        dispatch(OrganizationActions.setOrganizationRoles(res.data as OrgRoleItemResponse[]));
      }
      //
      setConfirmLoading(false);
      closeSelf();
    } catch (error) {
      setConfirmLoading(false);
      errorNotification(error);
    }
  }
  
  const handleOk = () => {
    form.validateFields().then(values => {
      storeOrganizationRole(values);
    });
  }

  const closeSelf = () => {
    dispatch(OrganizationActions.setOrganizationRoleSelected({}));
    dispatch(OrganizationActions.setModalState({ name: roleModalName, value: false }));
  }

  return (
    <Modal
      visible
      title={role?.id ? 'Edit Role' : 'New Role'}
      maskClosable={false}
      keyboard={false}
      confirmLoading={confirmLoading}
      onCancel={closeSelf}
      onOk={handleOk}
    >
      <Form 
        form={form} 
        layout="vertical" 
        autoComplete="off"
        onFinish={handleOk}
        requiredMark={false}
        initialValues={{ ...role }}
      >
        <Form.Item
          name="name"
          label="Role Name"
          rules={[{ required: true, message: 'This field is required!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
      {/* Todo: Permission Roles TBD */}
    </Modal>
  );
}