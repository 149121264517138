import React, { FC } from 'react'
import { List, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import {
  LicenseData,
  OrganizationActions,
  OrgModelName,
} from '~/Store/Organization'
import { LicenseAvatar } from '~/Components/LicenseAvatar'
import { useAppDispatch } from '~/Store'

interface LicensePanelHeaderProps {
  license: LicenseData
}

export const LicensePanelHeader: FC<LicensePanelHeaderProps> = ({
  license,
}) => {
  const dispatch = useAppDispatch()

  const showLicenseInfo = () => {
    dispatch(OrganizationActions.setLicenseSelected(license))
    dispatch(
      OrganizationActions.setModalState({
        name: OrgModelName.licenseInfoVisibility,
        value: true,
      }),
    )
  }

  return (
    <div style={{ display: 'inline-flex', width: '80%' }}>
      <List.Item.Meta
        avatar={
          <LicenseAvatar
            type={license.licenseType.type}
            height={50}
            width={50}
          />
        }
        title={
          <>
            <span style={{ fontWeight: 'bolder' }}>
              {license.licenseType.name}
            </span>
            <Tooltip title="License Info">
              <InfoCircleOutlined
                style={{ marginLeft: '20px', color: 'orange' }}
                data-id={license.guid}
                onClick={event => {
                  showLicenseInfo()
                  event.stopPropagation()
                }}
              />
            </Tooltip>
          </>
        }
        description={`${
          license.users?.filter(i => i.invitationStatus === 'Accepted').length
        } out of ${license.number} used`}
      />
    </div>
  )
}
