import { AxiosRequestConfig } from 'axios'
import { Get } from './Get'

class ProductServiceController {
  // prettier-ignore
  private licenseManagerUrl = process.env.LICENSE_MANAGER_URL ?? `${window.location.protocol}//${window.location.hostname}/license_manager`

  public Get = {
    getProducts: (options: AxiosRequestConfig = {}) => {
      return Get(`${this.licenseManagerUrl}/products`, options)
    },
    getProductClaimsById: (guid: string, options: AxiosRequestConfig = {}) => {
      return Get(`${this.licenseManagerUrl}/products/${guid}/claims`, options)
    },
  }

  public Post = {}
}

export const ProductService = new ProductServiceController()
