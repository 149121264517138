import React, { FC } from 'react';
import { UserItemData } from '~/Services/UserService.d';
import { Utils } from '~/Utils';
import styles from './Roles.module.less';
import { OrganizationsService, OrgRoleItemResponse } from '~/Services/OrganizationService';
import { errorNotification } from '~/Services';
import { WaitOverlayActions } from '~/Store/Overlays';
import { UserAvatar } from '~/Components/UserAvatar';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { getDefaultOrganization } from '~/Store/User/selectors';
import { MoreActionMenu } from './MoreActionMenu';
import { OrganizationActions } from '~/Store/Organization';
import { waitOverlayName } from './constant';

interface UserItemProps {
  user: UserItemData;
  role: OrgRoleItemResponse;
}

export const UserItem: FC<UserItemProps> = ({
  user,
  role,
}) => {
  const dispatch = useAppDispatch();
  const defaultOrg = useAppSelector(getDefaultOrganization);
  const fullName = Utils.combineStrings([user.firstname, user.lastname]);

  const removeRoleFromUser = async (
    evt: any,
    userId: string,
    role: string
  ) => {
    const { key } = evt;
    if (key === 'remove') {
      try {
        dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: true }));
        await OrganizationsService.Delete.removeOrganizationRolesFromUser(userId,[{ organizationId: defaultOrg?.guid, roleName: role }]);
        const res = await OrganizationsService.Get.getOrganizationUsers(defaultOrg?.guid, { paging: false });
        if (res.data) {
          dispatch(OrganizationActions.setOrganizationUsers(res.data as UserItemData[]));
        }
      } catch (error) {
        errorNotification(error);
      } finally {
        dispatch(WaitOverlayActions.setVisible({ name: waitOverlayName, value: false }));
      }
    }
  };

  return (
    <div key={user.id} className={styles.panelContent}>
      <div>
        <i className={styles.addIcon}>
          <UserAvatar user={user} />
        </i>
        {fullName || user.email}
      </div>
      {role.allowRemoveUser && (
        <MoreActionMenu
          userId={user.id}
          roleName={role.name}
          onClick={removeRoleFromUser}
        />
      )}
    </div>
  );
};
