import { Button, Col, Pagination, Row, Spin, Typography, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { Page, RedDotSvg, SubmitSuccessSvg } from '~/Components'
import { NoData } from '~/Components/NoData'
import { WaitOverlay } from '~/Components/Overlays/WaitOverlay'
import { ScrollBar } from '~/Components/ScrollBar'
import {
  getDeviceLoading,
  getFilterDevices,
  getListDevices,
} from '~/Store/Devices/selectors'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Device, DevicesActions } from '~/Store/Devices/slice'
import { useAppDispatch, useAppSelector } from '~/Store/hooks'
import { getLeftSiderCollapsed } from '~/Store/Layout/selectors'
import styles from './Devices.module.less'
import { DevicesFilter } from './DevicesFilter'
import { getDefaultOrganization } from '~/Store/User/selectors'
import { Utils } from '~/Utils'

export const Devices = () => {
  const dispatch = useAppDispatch()
  const waitOverlayName = 'fetchingDevices'
  const defaultOrg = useAppSelector(getDefaultOrganization)
  const lstDevices = useAppSelector(getListDevices)
  const filter = useAppSelector(getFilterDevices)
  const loading = useAppSelector(getDeviceLoading)
  const leftMenuCollapsed = useAppSelector(getLeftSiderCollapsed)

  useEffect(() => {
    dispatch(DevicesActions.getListDevices({ ...filter, page: 1 }))
  }, [defaultOrg])

  const changePage = async (_page: number, _pageSize?: number | undefined) => {
    dispatch(
      DevicesActions.getListDevices({
        ...filter,
        pageSize: _pageSize,
        page: _page,
      }),
    )
  }

  const deactivateDevices = (deactivateItem: Device) => {
    Modal.confirm({
      title: `Do you want to deactive the ${deactivateItem.device.name} device?`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <div>{`Flatform: ${deactivateItem.device.flatform}`}</div>
          <div>{`MAC: ${deactivateItem.device.mac}`}</div>
          {/* prettier-ignore */}
          <div>{`Last action time: ${Utils.toLocaleDateTimeString(deactivateItem.lastActionTime)}`}</div>
          {deactivateItem.user && (
            <div>{`User: ${deactivateItem.user.email}`}</div>
          )}
        </div>
      ),
      onOk() {
        dispatch(
          DevicesActions.deactivateDevices([
            {
              userId: deactivateItem.userId,
              mac: deactivateItem.device.mac,
              clientID: deactivateItem.clientId,
            },
          ]),
        )
      },
    })
  }

  return (
    <WaitOverlay
      name={waitOverlayName}
      size="large"
      wrapperClassName={styles.FullOverlay}
    >
      <div className={styles.OrganizationUsers}>
        <Row align="stretch">
          <Typography.Title style={{ flex: 1 }} level={4}>
            Devices
          </Typography.Title>
          <DevicesFilter />
        </Row>
        <div className={styles.UserList}>
          <ScrollBar style={{ paddingRight: '16px', marginRight: '-16px' }}>
            <div className={styles.UserItem} style={{ fontWeight: 'bold' }}>
              <Row align="middle">
                <Col xs={4} md={4}>
                  Name
                </Col>
                <Col xs={3} md={3}>
                  Flatform
                </Col>
                <Col xs={4} md={4}>
                  MAC
                </Col>
                <Col xs={2} md={2}>
                  Version
                </Col>
                <Col xs={4} md={4}>
                  User
                </Col>
                <Col xs={4} md={4}>
                  Last action time
                </Col>
                <Col xs={2} md={2} style={{ textAlign: 'center' }}>
                  Status
                </Col>
              </Row>
            </div>
            <Spin spinning={loading}>
              {lstDevices && lstDevices.length > 0 ? (
                lstDevices.map(item => (
                  <div
                    key={item.id}
                    className={styles.UserItem}
                    style={{
                      background: item.isActive
                        ? 'transparent'
                        : 'rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Row gutter={[8, 8]} align="middle">
                      <Col xs={4} md={4} style={{}} data-id={item.id}>
                        {item.device.name}
                      </Col>
                      <Col xs={3} md={3} data-id={item.id} style={{}}>
                        {item.device.flatform}
                      </Col>
                      <Col xs={4} md={4}>
                        {item.device.mac}
                      </Col>
                      <Col xs={2} md={2}>
                        {item.version}
                      </Col>
                      <Col xs={4} md={4}>
                        {item.user && item.user.email}
                      </Col>
                      <Col xs={4} md={4}>
                        {Utils.toLocaleDateTimeString(item.lastActionTime)}
                      </Col>
                      <Col
                        md={1}
                        style={{ textAlign: 'end', paddingTop: '5px' }}
                      >
                        {item.isActive ? (
                          <SubmitSuccessSvg height={15} width={15} />
                        ) : (
                          <RedDotSvg height={15} width={15} />
                        )}
                      </Col>
                      <Col md={2} style={{ textAlign: 'start' }}>
                        {item.isActive && (
                          <Button
                            danger
                            size="small"
                            onClick={() => deactivateDevices(item)}
                            style={{ marginLeft: '5px' }}
                          >
                            Deactive
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <NoData content="No data" />
              )}
            </Spin>
          </ScrollBar>
        </div>
        <div
          className={styles.Pagination}
          style={{
            width: `calc(100% - ${leftMenuCollapsed ? '146' : '266'}px)`,
          }}
        >
          <Pagination
            current={filter.page}
            total={filter.total}
            pageSize={filter.pageSize}
            onChange={changePage}
          />
        </div>
      </div>
    </WaitOverlay>
  )
}
