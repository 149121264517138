import React, { FC, SVGProps } from 'react'

export const RedDotSvg: FC<SVGProps<any>> = ({ height, width }) => (
  <svg
    width={width || 30}
    height={height || 30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
      fill="#D72828"
    />
  </svg>
)
