import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Input, Row, Space, Typography } from 'antd'
import Text from 'antd/es/typography/Text'
import { useHistory } from 'react-router-dom'
import { SyncOutlined } from '@ant-design/icons'
import { errorNotification, UserService } from '~/Services'
import styles from '../Form.module.less'
import { Captcha, initCaptcha } from '~/Types/Common'
import { Utils } from '~/Utils'

export const Register = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [error, setErrorState] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [captcha, setCaptcha] = useState<Captcha>(initCaptcha)
  const [timer, setTimer] = useState<any>(null)

  const registerAndAuthenticate = async (values: any) => {
    if (!captcha) {
      return
    }
    setErrorState(false)
    setLoading(true)
    try {
      const { firstName, lastName, userName, password, captchaCode } = values
      await UserService.Post.registerUser({
        firstName,
        lastName,
        email: userName,
        userName,
        password,
        captchaId: captcha.captchaId,
        captcha: captchaCode,
      })
      setLoading(false)
      history.replace('/registerconfirmation')
    } catch (err: any) {
      if (err.isAxiosError) {
        const { status, message } = err.toJSON()
        if (status === 400) {
          setErrorMsg('Bad Request. User already exists!')
        } else {
          setErrorMsg(message)
        }
      } else {
        setErrorMsg('An unknown issue has occured')
      }
      setErrorState(true)
      setLoading(false)
    }
  }

  const onRedirectToLogin = () => {
    history.push('/login')
  }

  const emailChange = () => {
    clearTimeout(timer)
    const timeoutId = setTimeout(() => {
      refetchingCaptcha()
    }, 500)
    setTimer(timeoutId)
  }

  const refetchingCaptcha = async () => {
    try {
      form.setFieldsValue({ captchaCode: '' })
      const email = form.getFieldValue('userName')
      if (email && Utils.validateEmail(email)) {
        const captchaRes = await UserService.Get.getCaptchaByEmail(email)
        const { data } = captchaRes
        setCaptcha({
          captchaId: data['CaptchaId'],
          captchaImage: data['Captcha'],
        })
      } else {
        setCaptcha(initCaptcha)
      }
    } catch (error) {
      setCaptcha(initCaptcha)
      errorNotification(error)
    }
  }

  const isEmail = () => {
    const email = form.getFieldValue('userName')
    return Utils.validateEmail(email)
  }

  return (
    <Row justify="space-around" align="middle" style={{ height: '100%' }}>
      <Col sm={24} md={18} lg={16} xl={12} xxl={8}>
        <div className={styles.form}>
          <div className={styles.header}>
            <Typography.Title level={4}>Đăng ký</Typography.Title>
            <span>Tạo tài khoản để sử dụng các dịch vụ của Hicas</span>
          </div>
          <Form
            layout="vertical"
            name="register"
            form={form}
            scrollToFirstError
            onFinish={registerAndAuthenticate}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item noStyle>
              <Form.Item
                label="Tên"
                name="firstName"
                style={{
                  display: 'inline-block',
                  width: '50%',
                  paddingRight: 8,
                }}
                rules={[{ required: true, message: 'Nhập tên của bạn' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Họ và Tên đệm"
                name="lastName"
                style={{
                  display: 'inline-block',
                  width: '50%',
                }}
                rules={[{ required: true, message: 'Nhập họ và tên đệm' }]}
              >
                <Input />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Nhập mật khẩu' }]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label="Xác nhận mật khẩu"
              name="Confirm password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Nhập mật khẩu xác nhận',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }

                    return Promise.reject(
                      new Error('Mật khẩu không trùng nhau!!!'),
                    )
                  },
                }),
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label="Địa chỉ email"
              name="userName"
              rules={[
                { type: 'email', message: 'Email không đúng định dạng' },
                { required: true, message: 'Nhập email của bạn' },
              ]}
              extra="Chúng tôi sẽ dùng email này để liên hệ với bạn"
            >
              <Input onChange={emailChange} />
            </Form.Item>
            <Form.Item label="Mã xác thực" style={{ marginBottom: 0 }}>
              <Form.Item
                name="captchaCode"
                style={{
                  display: 'inline-block',
                  width: 'calc(100% - 170px)',
                }}
                rules={[{ required: true, message: 'Nhập mã xác thực' }]}
              >
                <Input disabled={!isEmail()} />
              </Form.Item>
              <Form.Item
                name="captchaCode"
                style={{
                  display: 'inline-block',
                  width: 100,
                  marginLeft: 10,
                }}
              >
                {captcha ? (
                  <img
                    src={`data:image/png;base64,${captcha.captchaImage}`}
                    width={100}
                    height={30}
                  />
                ) : (
                  <Typography>Captcha</Typography>
                )}
              </Form.Item>
              <Form.Item
                name="captchaCode"
                style={{
                  display: 'inline-block',
                  width: 50,
                }}
              >
                <Button
                  type="text"
                  icon={
                    <SyncOutlined
                      style={{ fontSize: 18, fontWeight: 'bold' }}
                    />
                  }
                  disabled={!isEmail()}
                  onClick={refetchingCaptcha}
                />
              </Form.Item>
            </Form.Item>
            <Space direction="vertical">
              <Text type="danger">{error && errorMsg}</Text>
            </Space>
            <Form.Item>
              <Row>
                <Col span={14} style={{ paddingTop: '4px' }}>
                  <span>Bạn đã có tài khoản chưa?</span>
                  <Button
                    key="loginButton"
                    htmlType="button"
                    disabled={loading}
                    type="link"
                    onClick={onRedirectToLogin}
                  >
                    Đăng nhập
                  </Button>
                </Col>
                <Col span={10} style={{ textAlign: 'right' }}>
                  <Button
                    key="confirmButton"
                    form="register"
                    disabled={loading}
                    htmlType="submit"
                    type="primary"
                    size="large"
                    loading={loading}
                  >
                    Đăng ký
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
          <Divider />
          <div className={styles.footer}>
            <div>
              By continuing you agree to Hicas's <a href="">Terms of Service</a>{' '}
              and
            </div>
            <div>
              <a href="">Privacy Policy</a> and to receiving email with updates.
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}
