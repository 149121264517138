/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react';
import { Row, Col, Tag, Avatar } from 'antd';

import { OrgClaim } from '../../Routes';
import { UserItemData, UserInviteStatus } from '~/Services/UserService.d';
import styles from './Users.module.less';
import { Utils } from '~/Utils';

export const tagColor = (text: string) => {
  let color = 'blue';
  switch (text) {
    case OrgClaim.Member:
      color = 'green';
      break;
    case OrgClaim.Superuser:
      color = 'blue';
      break;
    case UserInviteStatus.Declined:
    case OrgClaim.Admin:
      color = 'red';
      break;
    case UserInviteStatus.Accepted:
      color = 'blue';
      break;
    case UserInviteStatus.Pending:
      color = 'orange';
      break;
    default:
      color = Utils.stringToColour(text);
      break;
  }
  return color;
};
interface UserItemProps {
  user: UserItemData;
  hasAdmin: boolean;
  onClick: () => void;
}

export const UserItem: FC<UserItemProps> = ({ user, onClick }) => {
  const fullname = Utils.combineStrings([user.firstname, user.lastname]);
  return (
    <div key={user.id} className={styles.UserItem}>
      <Row gutter={[8, 8]} align="middle">
        <Col
          xs={8}
          md={8}
          onClick={onClick}
          style={{ cursor: 'pointer' }}
          data-id={user.id}
        >
          <i style={{ fontStyle: 'normal', paddingRight: '8px' }}>
            <Avatar
              src={
                user.avatarUrl && !user.avatarUrl.startsWith('http')
                  ? `https://${user.avatarUrl}`
                  : undefined
              }
              style={{
                backgroundColor: user.avatarUrl
                  ? undefined
                  : Utils.stringToColour(user.id),
              }}
            >
              {fullname
                ? fullname.charAt(0).toLocaleUpperCase()
                : user.email.charAt(0).toLocaleUpperCase()}
            </Avatar>
          </i>
          {fullname}
        </Col>
        <Col
          xs={7}
          md={7}
          onClick={onClick}
          data-id={user.id}
          style={{ cursor: 'pointer' }}
        >
          {user.email}
        </Col>
        <Col xs={6} md={6}>
          {user.organizationRoles.map((tag) => (
            <Tag key={tag} color={tagColor(tag)}>
              {tag}
            </Tag>
          ))}
        </Col>
        <Col xs={3} md={3}>
          {user.invitationStatus && (
            <Tag color={tagColor(user.invitationStatus)}>
              {user.invitationStatus}
            </Tag>
          )}
        </Col>
      </Row>
    </div>
  );
};
