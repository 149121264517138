import React, { FC } from 'react';
import { List } from 'antd';
import { UserItemData } from '~/Services/UserService.d';
import { UserItemMeta } from '../UserItemMeta';

interface UserListProps {
  users: UserItemData[];
}

export const UserList: FC<UserListProps> = ({ users }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={users}
      split={false}
      renderItem={(item) => (
        <List.Item>
          <UserItemMeta user={item} />
        </List.Item>
      )}
    />
  );
};
