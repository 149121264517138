class Utils {
  public static delay = (ms: number) => new Promise(res => setTimeout(res, ms))

  static setLocalStorage(key: string, value: any): void {
    if (Array.isArray(value) || typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.setItem(key, value)
    }
  }

  static getValueLocalStorage(key: string): any | null {
    const value = localStorage.getItem(key)
    let re = null
    value && (re = Utils.parseJson(value))
    return re
  }

  static removeItemLocalStorage(key: string): void {
    localStorage.removeItem(key)
  }

  static parseJson(str: string): any | null {
    try {
      return JSON.parse(str)
    } catch (e) {
      return null
    }
  }

  public static encodeRFC5987ValueChars = (str: string) => {
    return (
      encodeURIComponent(str)
        // Note that although RFC3986 reserves "!", RFC5987 does not,
        // so we do not need to escape it
        .replace(/['()*]/g, c => '%' + c.charCodeAt(0).toString(16))
        // i.e., %27 %28 %29 %2a (Note that valid encoding of "*" is %2A
        // which necessitates calling toUpperCase() to properly encode)
        // The following are not required for percent-encoding per RFC5987,
        // so we can allow for a little better readability over the wire: |`^
        .replace(/%(7C|60|5E)/g, (str, hex) =>
          String.fromCharCode(parseInt(hex, 16)),
        )
    )
  }

  public static stringToColour = (str?: string) => {
    if (!str) return '#ccc'
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    let colour = (hash & 0x00ffffff).toString(16).toUpperCase()
    return '#' + '00000'.substring(0, 6 - colour.length) + colour
  }

  public static combineStrings(
    arrStrings: (string | undefined)[],
    separator = ' ',
  ) {
    return arrStrings.filter(x => x).join(separator)
  }

  public static avatarUrl(url?: string | null) {
    if (!url) return undefined
    if (url.startsWith('https://')) return url
    return `https://${url}`
  }

  public static getFirstInitLastName(
    email: string,
    firstname?: string,
    lastname?: string,
  ) {
    const fullName = this.combineStrings([firstname, lastname])
    return fullName[0] || email[0]
  }

  public static toLocaleDateTimeString = (date: Date | string | number) => {
    const d = new Date(date)
    // prettier-ignore
    return typeof d === 'string' ? d : `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
  }

  public static toLocaleDateString = (date: Date | string | number) => {
    const d = new Date(date)
    // prettier-ignore
    return typeof d === 'string' ? d : `${d.toLocaleDateString()}`
  }

  public static validateEmail = (mail: string) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true
    }
    return false
  }
}

export default Utils
