import React, { useEffect, useState } from 'react'
import { Badge, Popover, List, Tooltip } from 'antd'
import { BellOutlined, RightOutlined } from '@ant-design/icons'
import styles from './PageHeader.module.less'
import { useAppDispatch, useAppSelector } from '~/Store/hooks'
import {
  getCurrentUser,
  getUserOrganizationsByStatus,
} from '~/Store/User/selectors'
import { UserInviteStatus } from '~/Services/UserService.d'
import { useHistory } from 'react-router-dom'
import { UserActions } from '~/Store/User'

export const Notifications = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const pendingOrgs = useAppSelector(state =>
    getUserOrganizationsByStatus(state, [UserInviteStatus.Pending]),
  )
  const currentUser = useAppSelector(getCurrentUser)
  const [hasNotifications, setHasNotifications] = useState(0)
  const [visible, setNotificationsVisibility] = useState(false)

  const checkUserProfileCompleted = () => {
    if (!currentUser) return false
    if (
      !currentUser.firstname ||
      !currentUser.lastname ||
      !currentUser.phoneNumber ||
      !currentUser.profile
    )
      return false
    return true
  }

  useEffect(() => {
    if (!currentUser) {
      setHasNotifications(0)
      return
    }
    const userProfileCompleted = checkUserProfileCompleted()
    if (!userProfileCompleted || pendingOrgs.length > 0)
      setHasNotifications(pendingOrgs.length + (userProfileCompleted ? 0 : 1))
    else setHasNotifications(0)
  }, [pendingOrgs, currentUser])

  const navigationTo = (path: string) => {
    dispatch(UserActions.setEditState(true))
    history.push(path)
    setNotificationsVisibility(false)
  }

  return (
    <>
      {hasNotifications ? (
        <Popover
          placement="bottomRight"
          trigger="click"
          key="notifications"
          overlayClassName={styles.notificationPopover}
          visible={visible}
          onVisibleChange={() => setNotificationsVisibility(!visible)}
          content={
            <div className={styles.notification}>
              <List itemLayout="horizontal">
                {!checkUserProfileCompleted() && (
                  <List.Item
                    className={styles.notificationItem}
                    onClick={() => navigationTo('/profile')}
                  >
                    <List.Item.Meta
                      title="Your profile"
                      description="Your profile has not been completed"
                    />
                    <RightOutlined style={{ fontSize: 10, color: '#ccc' }} />
                  </List.Item>
                )}
                {pendingOrgs.length > 0 && (
                  <List.Item
                    className={styles.notificationItem}
                    onClick={() => navigationTo('/profile/invitation')}
                  >
                    <List.Item.Meta
                      title="Invitation"
                      description={`You have ${pendingOrgs.length} new invitation`}
                    />
                    <RightOutlined style={{ fontSize: 10, color: '#ccc' }} />
                  </List.Item>
                )}
              </List>
            </div>
          }
        >
          <Badge
            count={hasNotifications}
            dot
            offset={[-10, 10]}
            className={styles.iconButton}
          >
            <BellOutlined className={styles.iconFont} />
          </Badge>
        </Popover>
      ) : (
        <Tooltip title="You have no unread notifications">
          <Badge count={0} dot offset={[-10, 10]} className={styles.iconButton}>
            <BellOutlined className={styles.iconFont} />
          </Badge>
        </Tooltip>
      )}
    </>
  )
}
