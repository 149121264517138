import { combineReducers } from '@reduxjs/toolkit'
import { LoginModalReducer } from '~/Store/LoginModal'
import { UserReducer } from '~/Store/User'
import { WaitOverlayReducer } from '~/Store/Overlays'
import { LayoutReducer } from '~/Store/Layout'
import { OrganizationReducer } from './Organization'
import { GlobalReducer } from './Global'
import { DevicesReducer } from './Devices'
import { LicenseMgmtReducer } from './License'

const rootReducer = combineReducers({
  global: GlobalReducer,
  user: UserReducer,
  loginModal: LoginModalReducer,
  waitOverlays: WaitOverlayReducer,
  layout: LayoutReducer,
  organization: OrganizationReducer,
  devices: DevicesReducer,
  license: LicenseMgmtReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
