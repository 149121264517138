import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CreateLicenseTypeDto,
  ILicenseType,
} from '~/Services/License.interface'
import { IProduct } from '~/Services/Product.interface'
import { PageMeta } from '~/Types/Common'
import { ModalName } from './constants'

interface ModalVisibility {
  name: string
  visible: boolean
}
interface LicenseMgmtState {
  isLoading: boolean
  isConfirmLoading: boolean
  licenseTypes: ILicenseType[]
  licenseTypeSelected?: ILicenseType
  productList: IProduct[]
  pageMeta?: PageMeta
  modalVisibility: {
    [id: string]: { visible: boolean }
  }
}

const initialState: LicenseMgmtState = {
  isLoading: false,
  isConfirmLoading: false,
  licenseTypes: [],
  productList: [],
  modalVisibility: {},
}

export const LicenseMgmtSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    getLicenseTypes: (state, action) => {
      state.isLoading = true
    },
    getLicenseTypesSuccess: (state, action) => {
      const {
        results,
        page,
        pageCount,
        pageSize,
        queryCount,
        firstRowIndex,
        lastRowIndex,
      } = action.payload
      state.licenseTypes = results
      state.pageMeta = {
        page,
        pageCount,
        pageSize,
        queryCount,
        firstRowIndex,
        lastRowIndex,
      }
      state.isLoading = false
    },
    getLicenseTypesFail: state => {
      state.isLoading = false
    },
    setModalVisibility: (state, action: PayloadAction<ModalVisibility>) => {
      const { name, visible } = action.payload
      state.modalVisibility[name] = { visible }
    },
    setLicenseTypeSelected: (state, action) => {
      state.licenseTypeSelected = action.payload
    },
    updateLicenseType: (state, action) => {
      state.isConfirmLoading = true
    },
    updateLicenseTypeSuccess: (state, action) => {
      const { payload } = action
      state.isConfirmLoading = false
      const indexItem = state.licenseTypes.findIndex(
        x => x.guid === payload['guid'],
      )
      if (indexItem !== -1) {
        state.licenseTypes[indexItem] = payload
      }
      state.modalVisibility[ModalName.LicenseType] = { visible: false }
    },
    updateLicenseTypeFail: state => {
      state.isConfirmLoading = false
    },
    createLicenseType: (state, action: PayloadAction<CreateLicenseTypeDto>) => {
      state.isConfirmLoading = true
    },
    createLicenseTypeSuccess: (state, action) => {
      state.isConfirmLoading = false
      state.modalVisibility[ModalName.LicenseType] = { visible: false }
    },
    createLicenseTypeFail: state => {
      state.isConfirmLoading = false
    },
    licenseTypeAdminLoadData: state => {
      state.isLoading = true
    },
    licenseTypeAdminLoadDataSuccess: (state, action) => {
      const { licenseTypesData, productsData } = action.payload
      state.productList = productsData
      const {
        results,
        page,
        pageCount,
        pageSize,
        queryCount,
        firstRowIndex,
        lastRowIndex,
      } = licenseTypesData
      state.licenseTypes = results
      state.pageMeta = {
        page,
        pageCount,
        pageSize,
        queryCount,
        firstRowIndex,
        lastRowIndex,
      }
      state.isLoading = false
    },
    licenseTypeAdminLoadDataFail: state => {
      state.isLoading = false
    },
    deleteLicenseType: (state, action: PayloadAction<string>) => {
      state.isConfirmLoading = true
    },
    deleteLicenseTypeSuccess: state => {
      state.isConfirmLoading = false
    },
    deleteLicenseTypeFail: state => {
      state.isConfirmLoading = false
    },
  },
})

export const LicenseMgmtActions = LicenseMgmtSlice.actions
export const LicenseMgmtReducer = LicenseMgmtSlice.reducer
