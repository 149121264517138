import { AxiosRequestConfig } from 'axios'
import { Get } from './Get'
import { CreateLicenseTypeDto, UpdateLicenseTypeDto } from './License.interface'
import { Post } from './Post'
import { Put } from './Put'
import { Delete } from './Delete'

class LicenseServiceController {
  // prettier-ignore
  private licenseManagerUrl = process.env.LICENSE_MANAGER_URL ?? `${window.location.protocol}//${window.location.hostname}/license_manager`
  private url = `${this.licenseManagerUrl}/licenses`

  public Get = {
    getLicenseInfoById: (id: string, options: AxiosRequestConfig = {}) => {
      return Get(`${this.licenseManagerUrl}/licenseTypes/${id}`, options)
    },
    getLicenseTypes: (options: AxiosRequestConfig = {}) => {
      return Get(`${this.licenseManagerUrl}/licenseTypes`, options)
    },
  }

  public Post = {
    createLicenseType: (
      data: CreateLicenseTypeDto,
      options: AxiosRequestConfig = {},
    ) => {
      return Post(`${this.licenseManagerUrl}/licenseTypes`, data, options)
    },
  }

  public Put = {
    updateLicenseType: (
      guid: string,
      data: UpdateLicenseTypeDto,
      options: AxiosRequestConfig = {},
    ) => {
      return Put(
        `${this.licenseManagerUrl}/licenseTypes/${guid}`,
        data,
        options,
      )
    },
  }

  public Delete = {
    deleteLicenseType: (guid: string, options: AxiosRequestConfig = {}) => {
      return Delete(
        `${this.licenseManagerUrl}/licenseTypes/${guid}`,
        {},
        options,
      )
    },
  }
}

export const LicenseService = new LicenseServiceController()
