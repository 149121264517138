import React, { FC, useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Divider,
} from 'antd';
import { UserItemData } from '~/Services/UserService.d';
import styles from './Roles.module.less';
import { Utils } from '~/Utils';
import { errorNotification } from '~/Services';
import { OrganizationsService, OrgRoleItemResponse } from '~/Services/OrganizationService';
import { UserItemMeta } from '~/Components/UserItemMeta';
import { UserList } from '~/Components/UserList';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { getDefaultOrganization } from '~/Store/User/selectors';
import { getOrganizationUsersByRole, getRoleModalVisibility } from '~/Store/Organization/selectors';
import { OrganizationActions } from '~/Store/Organization';
import { OrgClaim } from '../../Routes';
import { assignRoleModalName } from './constant';

const { Option } = Select;

interface AssignToUserProps {
  role: OrgRoleItemResponse;
}

export const AssignToUser: FC<AssignToUserProps> = ({ role }) => {
  const dispatch = useAppDispatch();
  const defaultOrg = useAppSelector(getDefaultOrganization);
  const users = useAppSelector(state => getOrganizationUsersByRole(state, OrgClaim.Member));
  const modalName = `${assignRoleModalName}_${role.name}`;
  const visibility = useAppSelector(getRoleModalVisibility(modalName));
  const [form] = Form.useForm();
  const [assignList, setAssignList] = useState<UserItemData[]>([]);
  const [loading, setLoading] = useState(false);

  const toggleAssignToUsers = () => dispatch(OrganizationActions.setModalState({name: modalName, value: !visibility}));

  useEffect(() => {
    if (visibility) {
      setAssignList([]);
      form.resetFields();
    }
  }, [visibility]);

  const addUsersToAssignList = (values: any) => {
    const { userEmail } = values;
    const user = users.find((i) => i.email === userEmail);
    if (user && !assignList.find((i) => i.email === userEmail)) {
      setAssignList([...assignList, user]);
    }
    form.resetFields();
  };

  const assignRoleToUsers = async () => {
    try {
      setLoading(true);
      const ps: Promise<any>[] = [];
      assignList.forEach((i) =>
        ps.push(OrganizationsService.Post.addOrganizationRolesToUser(i.id, [{ organizationId: defaultOrg?.guid, roleName: role.name }]))
      );
      await Promise.all(ps);
      const res = await OrganizationsService.Get.getOrganizationUsers(defaultOrg?.guid, { paging: false });
      if (res.data) {
        dispatch(OrganizationActions.setOrganizationUsers(res.data as UserItemData[]));
      }
      setLoading(false);
      toggleAssignToUsers();
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  return (
    <>
      {assignList.length > 0 && (
        <>
          <UserList users={assignList} />
          <Divider style={{ margin: '0px 0px 10px 0px' }} />
        </>
      )}
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className={styles.assignToUsersForm}
        initialValues={{ userEmail: '' }}
        onFinish={addUsersToAssignList}
      >
        <Form.Item
          label="User email"
          name="userEmail"
          rules={[{ required: true, message: 'This field is required!' }]}
        >
          <Select
            placeholder="example@gmail.com"
            showSearch
            showArrow={false}
            autoFocus
            optionLabelProp="label"
            disabled={loading}
          >
            {users.map((item) => (
              <Option
                value={item.email}
                label={Utils.combineStrings([item.firstname, item.lastname]) ||item.email}
                key={item.id}
              >
                <UserItemMeta user={item} />
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              {assignList.length > 0 ? (
                <>
                  <Button type="link" htmlType="submit" disabled={loading}>
                    Add more users
                  </Button>
                  <Button
                    shape="round"
                    type="primary"
                    style={{ marginLeft: '8px' }}
                    loading={loading}
                    onClick={assignRoleToUsers}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button shape="round" type="primary" htmlType="submit">
                  Add users
                </Button>
              )}
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
