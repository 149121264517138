/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { Row, Space, Col, Form, Input, Button } from 'antd'
import Text from 'antd/es/typography/Text'
export interface UserInformation {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}
export const CreateUser = ({
  onSubmit,
}: {
  onSubmit?: (value: UserInformation) => void
}) => {
  const onFinish = (values: UserInformation) => {
    onSubmit && onSubmit(values)
  }

  return (
    <div>
      <Row justify="space-around">
        <Col span={20}>
          <Form
            layout="vertical"
            name="register"
            scrollToFirstError
            autoComplete="off"
            onFinish={onFinish}
            requiredMark={false}
          >
            <Form.Item
              label="Tên"
              name="firstName"
              rules={[{ required: true, message: 'Nhập tên của bạn' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Họ và Tên đệm"
              name="lastName"
              rules={[{ required: true, message: 'Nhập họ và tên đệm' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Nhập mật khẩu' }]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label="Xác nhận mật khẩu"
              name="confirmPassword"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Nhập mật khẩu xác nhận',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }

                    return Promise.reject(
                      new Error('Mật khẩu xác nhận phải trùng với mật khẩu!'),
                    )
                  },
                }),
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label="Địa chỉ email"
              name="email"
              rules={[
                { type: 'email', message: 'Email không đúng định dạng' },
                { required: true, message: 'Nhập email của bạn' },
              ]}
            >
              <Input />
            </Form.Item>
            <Space direction="vertical">
              <Text type="danger">{/* {error && errorMsg} */}</Text>
            </Space>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}
