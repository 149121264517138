import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { UserActions, UserSelectors } from '~/Store/User'
import { useAppDispatch } from '~/Store/hooks'
import { UserInviteStatus } from '~/Services/UserService.d'
import { RootState } from '~/Store/reducers'

const { SubMenu, Item } = Menu

interface UserMenuProps {
  closeSelf: () => void
}

export const UserMenu = (props: UserMenuProps) => {
  const { closeSelf } = props
  const dispatch = useAppDispatch()
  const history = useHistory()
  const preferences = useSelector(UserSelectors.getUserPreferences)
  const organizations = useSelector((state: RootState) =>
    UserSelectors.getUserOrganizationsByStatus(state, [
      UserInviteStatus.Accepted,
    ]),
  )
  const defaultOrg = useSelector(UserSelectors.getDefaultOrganization)
  const [selectedOrg, setSelectedOrg] = useState<string>(defaultOrg?.guid)

  useEffect(() => {
    setSelectedOrg(old => (defaultOrg ? defaultOrg.guid : old))
  }, [defaultOrg])

  const userLogout = () => {
    dispatch(UserActions.logout())
    closeSelf()
    history.replace('/login')
  }

  const changeOrganization = (event: any) => {
    const { key } = event
    closeSelf()
    dispatch(UserActions.setAuthenticatedWithOrg(false))
    console.log('---------------')
    console.log(preferences)
    dispatch(
      UserActions.setUserPreferences({
        ...preferences,
        defaultOrganization: key,
      }),
    )
  }

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={['organizations']}
      selectedKeys={[selectedOrg]}
      onClick={changeOrganization}
    >
      {organizations.length > 0 && (
        <>
          <SubMenu key="organizations" title="Organizations">
            {organizations.map(org => (
              <Item key={org.guid}>{org.companyName}</Item>
            ))}
          </SubMenu>
          <Menu.Divider />
        </>
      )}
      <Item key="logout" onClick={userLogout}>
        Logout
      </Item>
    </Menu>
  )
}
