export enum ActiveState {
  Active = 'Active',
  Deactive = 'Deactive',
}

export enum LicenseTypeEnum {
  free = 'Free',
  standard = 'Standard',
  profession = 'Profession',
  premium = 'Premium',
}

export type LicenseTypeName = keyof typeof LicenseTypeEnum
