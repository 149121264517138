import axios from 'axios'
import { store } from '~/Store'
import { UserService } from '~/Services'
import { UserActions } from '~/Store/User'
import { getDefaultOrganization, getToken } from '~/Store/User/selectors'
import { notification } from 'antd'
import { Utils } from '~/Utils'

const { dispatch } = store

const instance = axios.create()

let history: any

export const injectHistory = (_history: any) => {
  history = _history
}

// Request interceptor for API calls
instance.interceptors.request.use(
  config => {
    const state = store.getState()
    const token = getToken(state)
    if (token && !fetchAccessTokenPromise) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
    return config
  },
  error => Promise.reject(error),
)

let fetchAccessTokenPromise: Promise<any> | null = null // this holds any in-progress token refresh requests
const fetchAccessToken = async (refreshToken: string) => {
  if (!fetchAccessTokenPromise) {
    const state = store.getState()
    const defaultOrg = getDefaultOrganization(state)
    fetchAccessTokenPromise = UserService.Post.login({
      grant_type: 'refresh_token',
      scope: 'API offline_access',
      refresh_token: refreshToken,
      orgId: defaultOrg?.guid,
    })
  }
  return fetchAccessTokenPromise
}

export const refreshAccessToken = async (refreshToken: string) => {
  try {
    const res = await fetchAccessToken(refreshToken)

    const { data } = res
    if (data.refresh_token && localStorage.getItem('refresh_token')) {
      localStorage.setItem('refresh_token', data.refresh_token)
    }
    dispatch(UserActions.setToken(data.access_token))
    dispatch(UserActions.setRefreshToken(data.refresh_token || ''))
    Utils.setLocalStorage('token', data.access_token)
    Utils.setLocalStorage('refresh_token', data.refresh_token)
    const state = store.getState()
    const defaultOrg = getDefaultOrganization(state)
    if (defaultOrg) dispatch(UserActions.setAuthenticatedWithOrg(true))
    return data.access_token
  } catch (error) {
    console.log('invalid refresh token')
    // clear store and redirect to login page
    dispatch(UserActions.logout())
    if (history) history.push('/login')
    else window.location.href = '/login'
  } finally {
    fetchAccessTokenPromise = null
  }
}

export const errorNotification = (error: any) => {
  // Handling Errors
  let errMessage = ''
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      errMessage =
        (error.response.data && error.response.data.Message) ||
        `${error.response.status} ${error.response.statusText}`
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      errMessage = 'No Server Response'
    } else {
      // Something happened in setting up the request that triggered an Error
      errMessage = error.message
    }
  } else if (typeof error === 'string') {
    errMessage = error
  } else {
    errMessage = 'Different error than axios'
    console.log(error)
  }
  notification.error({
    message: 'Oops!',
    description: errMessage,
  })
}

// Response interceptor for API calls
instance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    const refreshToken = localStorage.getItem('refresh_token')
    if (
      refreshToken &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      const accessToken = await refreshAccessToken(refreshToken)
      if (!accessToken) return Promise.reject(error)
      originalRequest.headers.Authorization = `Bearer ${accessToken}`
      return instance(originalRequest)
    }
    return Promise.reject(error)
  },
)

export default instance
