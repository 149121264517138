import {
  MoreOutlined,
  CaretRightOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import { Avatar, Collapse, Dropdown, Menu, Row, Typography, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { WaitOverlay } from '~/Components'
import { ScrollBar } from '~/Components/ScrollBar'
import { errorNotification } from '~/Services'
import { OrganizationsService } from '~/Services/OrganizationService'
import { useAppDispatch, useAppSelector } from '~/Store/hooks'
import {
  OrganizationActions,
  OrganizationSelectors,
} from '~/Store/Organization'
import { WaitOverlayActions } from '~/Store/Overlays'
import { UserSelectors } from '~/Store/User'
import { Utils } from '~/Utils'
import { AddUser } from './AddUser'
import { LicenseInfo } from './LicenseInfo'
import { LicensePanelHeader } from './LicensePanelHeader'

import styles from './Licenses.module.less'

const { Title } = Typography
const { Panel } = Collapse

export const Licenses = () => {
  const dispatch = useAppDispatch()
  const organization = useAppSelector(UserSelectors.getDefaultOrganization)
  const orgLicenses = useAppSelector(OrganizationSelectors.getOrgLicenses)
  const [activeLicense, setActiveLicense] = useState<
    string[] | string | undefined
  >()

  const { guid } = organization || {}

  const waitOverlay = 'loadingOrgLicenses'

  const fetchOrgLicense = async () => {
    try {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlay, value: true }),
      )
      const req: any = await OrganizationsService.Get.getOrganizationLicense(
        guid,
      )
      const { data } = req
      dispatch(OrganizationActions.setOrgLicense(data))
    } catch (err) {
      errorNotification(err)
    } finally {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlay, value: false }),
      )
    }
  }
  useEffect(() => {
    fetchOrgLicense()
  }, [])

  useEffect(() => {
    setActiveLicense(orgLicenses.length > 0 ? orgLicenses[0].guid : undefined)
  }, [orgLicenses.length])

  const RemoveOrRevokeUserLicense = async (
    evt: any,
    licenseTypeId: string,
    userId: string,
  ) => {
    try {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlay, value: true }),
      )
      const { key } = evt
      if (key === 'remove') {
        await OrganizationsService.Delete.removeUsersFromOrgLicense(userId, [
          licenseTypeId,
        ])
      }
      if (key === 'revoke') {
        // todo
      }
      await fetchOrgLicense()
    } catch (error) {
      errorNotification(error)
      // todo
    } finally {
      dispatch(
        WaitOverlayActions.setVisible({ name: waitOverlay, value: false }),
      )
    }
  }

  const menu = (licenseTypeId: string, userId: string) => (
    <Menu
      onClick={evt => RemoveOrRevokeUserLicense(evt, licenseTypeId, userId)}
    >
      <Menu.Item key="remove">{"Remove user's license"}</Menu.Item>
    </Menu>
  )

  const activeLicenseCallback = (key: string[] | string | undefined) => {
    setActiveLicense(key)
  }

  return (
    <WaitOverlay name={waitOverlay} size="large">
      <Row align="stretch">
        <Title style={{ flex: 1 }} level={4}>
          Licenses
        </Title>
        {/* <Button icon={<PlusCircleOutlined />} type="primary">
          New License
        </Button> */}
      </Row>
      <div className={styles.licenseList}>
        <ScrollBar style={{ paddingRight: '16px', marginRight: '-16px' }}>
          <Collapse
            accordion
            expandIconPosition="right"
            activeKey={activeLicense}
            onChange={activeLicenseCallback}
            // eslint-disable-next-line react/no-unstable-nested-components
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            ghost
            className={styles.licenseCollapse}
          >
            {orgLicenses.map(lic => (
              <Panel
                className={styles.licenseCollapsePanel}
                header={<LicensePanelHeader license={lic} />}
                key={lic.guid}
                extra={
                  <AddUser
                    licenseId={lic.guid}
                    licenseTypeId={lic.licenseTypeId}
                    refetchOrgLicenses={fetchOrgLicense}
                    openLicensePanel={activeLicenseCallback}
                  />
                }
              >
                {lic.users?.map((item: any) => (
                  <div key={item.id} className={styles.panelContent}>
                    <div>
                      <i className={styles.addIcon}>
                        <Avatar
                          src={
                            item.avatarUrl && !item.avatarUrl.startsWith('http')
                              ? `https://${item.avatarUrl}`
                              : undefined
                          }
                          style={{
                            backgroundColor: item.avatarUrl
                              ? undefined
                              : Utils.stringToColour(item.id),
                          }}
                        >
                          {(
                            Utils.combineStrings([
                              item.firstname,
                              item.lastname,
                            ]) || item.email
                          )
                            .charAt(0)
                            .toLocaleUpperCase()}
                        </Avatar>
                      </i>
                      {Utils.combineStrings([item.firstname, item.lastname])}
                    </div>
                    <Dropdown
                      overlay={menu(lic.licenseTypeId, item.id)}
                      trigger={['click']}
                    >
                      <MoreOutlined className={styles.addUserIcon} />
                    </Dropdown>
                  </div>
                ))}
              </Panel>
            ))}
          </Collapse>
        </ScrollBar>
      </div>
      <LicenseInfo />
    </WaitOverlay>
  )
}
