import React from 'react'
import { Menu } from 'antd'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { SystemRoutes } from '../Routes'
import { Page, PrivateRoute } from '~/Components'

export const Admin = () => {
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const navigate = (path: string) => {
    history.push(`${match.path}${path}`)
  }

  const activeKeys = (): string => {
    const currentTab = SystemRoutes.find(
      ({ routeProps: { path } }) =>
        `${match.path}${path}` === location.pathname,
    )
    return currentTab ? currentTab.label : 'info'
  }

  return (
    <>
      <Menu
        mode="horizontal"
        selectedKeys={[activeKeys()]}
        style={{ background: '#FFF' }}
      >
        {SystemRoutes.map(({ label, routeProps: { path } }) => (
          <Menu.Item key={label} onClick={() => navigate(path!.toString())}>
            {label}
          </Menu.Item>
        ))}
      </Menu>
      <Page title="System Admin" style={{ height: 'calc(100% - 46px)' }}>
        <Switch>
          {SystemRoutes.map(
            ({
              label,
              rootPermission,
              orgPermission,
              routeProps: { path, ...rest },
            }) => (
              <PrivateRoute
                allowedRoles={[...rootPermission, ...orgPermission]}
                key={`${label}`}
                exact
                path={`${match.path}${path}`}
                {...rest}
              />
            ),
          )}
          <Redirect to="/Admin" />
        </Switch>
      </Page>
    </>
  )
}
