import React from 'react'
import { Layout, Menu } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import Home from 'remixicon-react/HomeLineIcon'
import CaratRight from 'remixicon-react/ArrowRightSLineIcon'
import CaratLeft from 'remixicon-react/ArrowLeftSLineIcon'
import { useSelector } from 'react-redux'
import { HiCasLogoSvg, FloatingActionButton } from '~/Components'
import { Routes } from './Routes'
import { LayoutSelectors, LayoutActions } from '~/Store/Layout'
import { useAppDispatch } from '~/Store/hooks'
import { Icon } from '~/Components/Icons'
import styles from './LeftMenu.module.less'

const { getLeftSiderCollapsed } = LayoutSelectors
const { toggleLeftSider } = LayoutActions

export const LeftMenu = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const collapsed = useSelector(getLeftSiderCollapsed)
  const onCollapse = () => dispatch(toggleLeftSider())
  const navigate = ({ key }: any) => {
    history.push(key)
  }

  const activeKey = (): string => {
    const item = Routes.find(route =>
      location.pathname.startsWith(`${route.routeProps.path as string}`),
    )
    if (!item) {
      return 'home'
    }
    return item.routeProps.path as string
  }
  return (
    <Layout.Sider collapsible trigger={null} collapsed={collapsed}>
      <div
        style={{
          padding: collapsed ? '26px 16px 0px 16px' : '16px 16px 0px 16px',
          width: '100%',
          background: '#f0f2f5',
          textAlign: 'center',
          minHeight: '77px',
        }}
      >
        {collapsed ? (
          <HiCasLogoSvg width={100} height={40} />
        ) : (
          <HiCasLogoSvg width={130} height={50} />
        )}
      </div>

      <FloatingActionButton top="106px" right="-14px" onClick={onCollapse}>
        {collapsed ? (
          <CaratRight style={{ verticalAlign: 'middle' }} />
        ) : (
          <CaratLeft style={{ verticalAlign: 'middle' }} />
        )}
      </FloatingActionButton>
      <Menu
        onSelect={navigate}
        className={styles.LeftMenu}
        theme="dark"
        selectedKeys={[activeKey()]}
      >
        <Menu.Item key="home" icon={<Home />}>
          Home
        </Menu.Item>
        {Routes.map(route => (
          <Menu.Item key={route.routeProps.path as string} icon={route.icon}>
            {route.label}
          </Menu.Item>
        ))}
      </Menu>
    </Layout.Sider>
  )
}
