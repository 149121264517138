import { combineEpics } from 'redux-observable'
import { DevicesEpics } from './Devices'
import { LicenseMgmtEpics } from './License'
import { OrganizationEpics } from './Organization/epics'
import { UserEpics } from './User/epics'

const rootEpic = combineEpics(
  ...OrganizationEpics,
  ...DevicesEpics,
  ...UserEpics,
  ...LicenseMgmtEpics,
)

export default rootEpic
