import { createSelector } from '@reduxjs/toolkit'
import { UserInviteStatus } from '~/Services/UserService.d'
import { RootState } from '../reducers'

export const getCompanyName = (state: RootState) =>
  state.organization.info.companyName
export const getBillingAddress = (state: RootState) =>
  state.organization.info.billingAddress
export const getBillingAddress2 = (state: RootState) =>
  state.organization.info.billingAddress2
export const getOrgCity = (state: RootState) =>
  state.organization.info.billingCity
export const getOrgState = (state: RootState) =>
  state.organization.info.billingState
export const getOrgZipCode = (state: RootState) =>
  state.organization.info.billingZip
export const getOrgCrmId = (state: RootState) => state.organization.info.crmId
export const getOrgDuns = (state: RootState) => state.organization.info.duns
export const getOrgNaics = (state: RootState) => state.organization.info.naics
export const getOrgGuid = (state: RootState) => state.organization.info.guid
export const getOrg = (state: RootState) => state.organization
export const getOrganizationListUsersRefetch = (state: RootState) =>
  state.organization.users.refetch
export const getOrganizationListUsers = (state: RootState) =>
  state.organization.users.data
export const getOrgUserCurrentPage = (state: RootState) =>
  state.organization.users.currentPage
export const getOrganizationUsersTotal = (state: RootState) =>
  state.organization.users.total
export const getUserFilterState = (state: RootState) =>
  state.organization.users.filter
export const getOrgLicenses = (state: RootState) => state.organization.licenses
export const getIsEditMode = (state: RootState) => state.organization.isEditMode
export const getOrganizationRoles = (state: RootState) =>
  state.organization.roles
export const getOrganizationRoleSelected = (state: RootState) =>
  state.organization.roleSelected
export const getOrganizationUsers = (state: RootState) =>
  state.organization.orgUsers
export const getOrganizationUsersByRole = createSelector(
  [getOrganizationUsers, (_: unknown, orgRole: string) => orgRole],
  (users, role) =>
    users.filter(
      i =>
        i.organizationRoles.includes(role) &&
        i.invitationStatus === UserInviteStatus.Accepted,
    ),
)
export const getRoleModalVisibility = (name: string) => (state: RootState) =>
  state.organization.modalState[name]
export const getModalVisibility = (name: string) => (state: RootState) =>
  state.organization.modalState[name] || false
export const getRoleActivePanel = (state: RootState) =>
  state.organization.roleActivePanel

export const getLicenseSelected = (state: RootState) =>
  state.organization.licenseSelected
export const getLicenseInfo = (state: RootState) =>
  state.organization.licenseInfo
export const getLoading = (state: RootState) => state.organization.isLoading
export const getUsersToInvite = (state: RootState) =>
  state.organization.usersToInvite
